import { Card } from '@/components/core/cards/Card.tsx'
import { ServiceActivitySnapshot } from '@/generated'
import { ReactElement } from 'react'
import SnapshotSections from '@/pages/serviceActivity/residential/snapShots/components/SnapshotSections.tsx'
import SnapshotSection from '@/pages/serviceActivity/residential/snapShots/components/SnapshotSection.tsx'
import SnapshotLine from '@/pages/serviceActivity/residential/snapShots/components/SnapshotLine.tsx'
import SnapshotValue from '@/pages/serviceActivity/residential/snapShots/components/SnapshotValue.tsx'
import SnapshotSummary from '@/pages/serviceActivity/residential/snapShots/components/SnapshotSummary.tsx'
import { formatNumber, PercentNumberFormat } from '@/helpers/NumberFormats.ts'
import moneyIcon from '@iconify/icons-material-symbols/attach-money'
import phoneIcon from '@iconify/icons-material-symbols/call-outline'
import truckIcon from '@iconify/icons-material-symbols/local-shipping-outline'
import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import { InfoTooltip } from '@/pages/serviceActivity/residential/snapShots/components/InfoTooltip.tsx'
import SnapshotIcon from '@/pages/serviceActivity/residential/snapShots/components/SnapshotIcon.tsx'
import useServiceActivityObjectName from '@/pages/serviceActivity/useServiceActivityObjectName.ts'

type Props = {
  snapshot?: ServiceActivitySnapshot
  icon: React.JSX.Element
  description: string
  ticketLabel: string
  ticketTooltip: ReactElement | null
  percentLabel: string
  showAdditionalFields?: boolean
}

export default function SnapshotCard({
  snapshot,
  icon,
  description,
  ticketLabel,
  ticketTooltip,
  percentLabel,
  showAdditionalFields = false,
}: Readonly<Props>) {
  const { serviceCloudIntegrationEnabled } = useFeatureFlags()
  const objectName = useServiceActivityObjectName()

  // Early return if no snapshot is provided
  if (!snapshot) return null

  const {
    countOfServiceActivitiesOpenedForCategory = 0,
    percentageOfAssetsWithServiceActivityOpenedForCategory = 0,
    countOfPendingClientActions,
    countOfAssets,
    countOfNonCoveredServiceRequests,
    countOfRemoteTroubleShootings,
    countOfFieldServices,
  } = snapshot

  return (
    <Card className="tw-h-full">
      <SnapshotSummary
        icon={icon}
        count={countOfAssets}
        description={description}
      />

      <SnapshotSections>
        <SnapshotSection>
          <SnapshotLine
            label={ticketLabel}
            icon={
              ticketTooltip ? (
                <InfoTooltip
                  content={
                    <div className="tw-max-w-48 tw-text-left">
                      {ticketTooltip}
                    </div>
                  }
                  aria-label={`${objectName} Information`}
                />
              ) : null
            }
          >
            <SnapshotValue
              displayType="primary"
              value={formatNumber(countOfServiceActivitiesOpenedForCategory)}
            />
          </SnapshotLine>

          <SnapshotLine
            label={percentLabel}
            icon={
              serviceCloudIntegrationEnabled ? null : (
                <InfoTooltip
                  content={
                    <div className="tw-max-w-48 tw-text-left">
                      This percentage reflects the number of{' '}
                      <span className="tw-font-bold">assets</span> with at least
                      one open {objectName.toLowerCase()}, not the total number
                      of {objectName.toLowerCase()}s. Multiple{' '}
                      {objectName.toLowerCase()}s per asset aren&apos;t counted
                      to avoid skewing the ratio.
                    </div>
                  }
                  aria-label="Percentage information"
                />
              )
            }
          >
            <SnapshotValue
              value={PercentNumberFormat().positive.format(
                percentageOfAssetsWithServiceActivityOpenedForCategory
              )}
            />
          </SnapshotLine>
        </SnapshotSection>

        <SnapshotSection>
          <SnapshotLine
            label="Pending client action"
            icon={
              <div
                data-testid="pending-client-action-flag"
                className="tw-h-3 tw-w-3 tw-rounded-full tw-bg-red-500"
              />
            }
          >
            <SnapshotValue
              value={formatNumber(countOfPendingClientActions)}
              displayType="danger"
            />
          </SnapshotLine>
        </SnapshotSection>

        <SnapshotSection show={showAdditionalFields}>
          <SnapshotLine
            label="Non-Covered Service Requests"
            icon={<SnapshotIcon icon={moneyIcon} />}
          >
            <SnapshotValue
              value={formatNumber(countOfNonCoveredServiceRequests)}
            />
          </SnapshotLine>
        </SnapshotSection>
        <SnapshotSection show={showAdditionalFields}>
          <SnapshotLine
            label="Remote Troubleshooting"
            icon={<SnapshotIcon icon={phoneIcon} />}
          >
            <SnapshotValue
              value={formatNumber(countOfRemoteTroubleShootings)}
            />
          </SnapshotLine>

          <SnapshotLine
            label="Field Service"
            icon={<SnapshotIcon icon={truckIcon} />}
          >
            <SnapshotValue value={formatNumber(countOfFieldServices)} />
          </SnapshotLine>
        </SnapshotSection>
      </SnapshotSections>
    </Card>
  )
}
