import { createContext } from 'react'

export type UserAnalyticsContextValue = {
  addUserProperties: (properties: UserProperties) => void
}

export type UserProperties = {
  clientProfile?: string
}

export const UserAnalyticsContext =
  createContext<UserAnalyticsContextValue | null>(null)
