import { PageCard } from '@/components/cards/PageCard.tsx'
import useTable from '@/components/core/table/useTable.tsx'
import { useCommercialServiceActivityQuery } from '@/api/ServiceActivityQueries.ts'
import useCommercialServiceActivityTableColumns from '@/pages/serviceActivity/commercial/useCommercialServiceActivityTableColumns.tsx'
import { useBrandingQuery } from '@/api/ApiQueries.ts'
import { CommercialServiceActivity, PageName } from '@/generated'
import useCommercialServiceActivityTableFilters from '@/pages/serviceActivity/commercial/useCommercialServiceActivityTableFilters.tsx'
import useCommercialServiceActivityCsvColumns from '@/pages/serviceActivity/commercial/useCommercialServiceActivityCsvColumns.ts'
import { Row } from '@tanstack/react-table'
import PageBody from '@/pages/components/PageBody.tsx'
import { ServiceActivityPageSize } from '@/features/serviceactivity/ServiceActivityPageSize.ts'
import ServiceActivityTable from '@/pages/serviceActivity/shared/ServiceActivityTable.tsx'
import { datedCsvFileName } from '@/helpers/ExportCsvHelpers.ts'
import ServiceActivityCsvContextProvider from '../ServiceActivityCsvContextProvider'
import DataRefreshMessage from '@/pages/serviceActivity/shared/DataRefreshMessage.tsx'
import { useHashState } from '@/routing/useHashState.ts'
import { useMemo } from 'react'
import CommercialServiceActivityViewer from '@/pages/serviceActivity/commercial/CommercialServiceActivityViewer.tsx'
import {
  serviceActivityHashParamName,
  workOrderTabHashParamName,
} from '@/pages/serviceActivity/shared/serviceActivityHashParamNames.ts'

const emptyServiceActivityData: CommercialServiceActivity[] = []
export default function CommercialServiceActivityPage() {
  const { data: branding } = useBrandingQuery()
  const serviceActivityQuery = useCommercialServiceActivityQuery()

  const [selectedActivityId, setSelectedActivityId] = useHashState(
    serviceActivityHashParamName,
    [workOrderTabHashParamName]
  )

  /*
   * Find the selected activity from the query data.
   * TODO: Remove this lookup once we fully migrate to SerViceCloud since TicketSummaryModal rendered in CommercialServiceActivityViewer is the only component who needs the full ServiceActivity Object
   */
  const selectedServiceActivity = useMemo(
    () =>
      selectedActivityId
        ? serviceActivityQuery.data?.find(sa => sa.id === selectedActivityId)
        : undefined,
    [serviceActivityQuery.data, selectedActivityId]
  )

  const columns = useCommercialServiceActivityTableColumns(
    PageName.ServiceActivity
  )
  const exportCsvColumns = useCommercialServiceActivityCsvColumns(
    PageName.ServiceActivity
  )
  const filterDefs = useCommercialServiceActivityTableFilters(
    PageName.ServiceActivity
  )
  const rows = serviceActivityQuery.data ?? emptyServiceActivityData
  const tableModel = useTable(rows, columns, {
    initialSort: [{ id: 'priority', desc: false }],
    pageSize: ServiceActivityPageSize,
  })

  const handleClickRow = (row: Row<CommercialServiceActivity>) => {
    setSelectedActivityId(row.original.id)
  }

  return (
    <PageBody bgColor="gray">
      <div className="tw-mb-8 tw-flex tw-justify-between">
        <div>
          <div className="tw-mb-3 tw-font-semibold">Open Service Activity</div>
          <div className="tw-text-gray-500">
            The following table shows all open service activity{' '}
            {branding?.name ? `for ${branding.name}` : ''}.
          </div>
        </div>
        <DataRefreshMessage />
      </div>
      <PageCard>
        <ServiceActivityCsvContextProvider
          pageName={PageName.ServiceActivity}
          tableModel={tableModel}
          csvOptions={{
            columns: exportCsvColumns,
            fileName: datedCsvFileName('service_activity'),
          }}
        >
          <ServiceActivityTable
            tableModel={tableModel}
            isLoading={serviceActivityQuery.isLoading}
            isError={serviceActivityQuery.isError}
            filterDefs={filterDefs}
            handleClickRow={handleClickRow}
          />
        </ServiceActivityCsvContextProvider>
        {selectedServiceActivity && (
          <CommercialServiceActivityViewer
            selectedServiceActivity={selectedServiceActivity}
            setSelectedActivityId={setSelectedActivityId}
          />
        )}
      </PageCard>
    </PageBody>
  )
}
