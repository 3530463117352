import CaseCommunications from '@/features/serviceactivity/detailsDrawer/communications/components/list/body/CaseCommunications.tsx'
import { useCommunicationsQuery } from '@/api/CaseQueries.ts'
import { Card } from '@/components/core/cards/Card.tsx'
import { SolarSpinner } from '@/components/core/spinner/SolarSpinner.tsx'
import Alert from '@/components/core/alerts/Alert.tsx'
import CaseCommunicationProvider from '@/features/serviceactivity/detailsDrawer/communications/components/list/CaseCommunicationsProvider.tsx'

export type Props = Readonly<{
  caseId: string
}>
export default function CaseCommunicationsPanel({ caseId }: Props) {
  const query = useCommunicationsQuery(caseId)
  return (
    <Card>
      {query.isPending && <SolarSpinner />}
      {query.isError && (
        <Alert color="red">
          There was an error loading communications for this case. Please try
          again later.
        </Alert>
      )}
      {query.isSuccess && (
        <CaseCommunicationProvider caseId={caseId}>
          <CaseCommunications items={query.data} />
        </CaseCommunicationProvider>
      )}
    </Card>
  )
}
