import type { PropsWithChildren } from 'react'
import { twMerge } from '@/helpers/CustomTwMerge.ts'

export interface VerticalPropertyItemProps {
  label: string
  className?: string
}

export default function VerticalPropertyItem({
  label,
  className,
  children,
}: PropsWithChildren<VerticalPropertyItemProps>) {
  return (
    <div className={twMerge('tw-flex tw-grow tw-flex-col tw-gap-1', className)}>
      <span className="tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-500">
        {label}
      </span>
      <span className="tw-text-gray-900">{children}</span>
    </div>
  )
}
