import QuoteLineItem from '@/features/serviceactivity/detailsDrawer/workOrders/quotes/components/body/client/QuoteLineItem.tsx'
import { PercentNumberFormat } from '@/helpers/NumberFormats.ts'
import { WorkOrderQuote } from '@/generated'

export type Props = Readonly<{
  quote: WorkOrderQuote
}>
export default function QuoteCosts({ quote }: Props) {
  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <QuoteLineItem label="Mobilization" value={quote.mobilizationCost} />
      <QuoteLineItem label="Labor" value={quote.laborCost} />
      <QuoteLineItem label="Travel" value={quote.travelCost} />
      <QuoteLineItem label="Equipment" value={quote.equipmentCost} />
      <QuoteLineItem label="Material" value={quote.materialCost} />
      <QuoteLineItem label="Other" value={quote.otherCost} />
      <QuoteLineItem label="Tax" value={quote.taxCost} />
      <QuoteLineItem
        label={`Mark-Up (${PercentNumberFormat().positive.format(quote.omnidianMarkupPercentage)})`}
        value={quote.omnidianMarkupAmount}
      />
      <QuoteLineItem
        label="Total Cost"
        value={quote.clientTotalCost}
        className="tw-pt-2 tw-text-lg tw-font-bold tw-text-gray-900"
      />
    </div>
  )
}
