import { useMemo } from 'react'
import { Row } from '@tanstack/react-table'
import { CommercialServiceActivity, PageName } from '@/generated'
import useTable from '@/components/core/table/useTable.tsx'
import { useCommercialAssetServiceActivityQuery } from '@/api/AssetQueries.ts'
import useCommercialServiceActivityCsvColumns from '@/pages/serviceActivity/commercial/useCommercialServiceActivityCsvColumns.ts'
import useCommercialServiceActivityTableColumns from '@/pages/serviceActivity/commercial/useCommercialServiceActivityTableColumns.tsx'
import useCommercialServiceActivityTableFilters from '@/pages/serviceActivity/commercial/useCommercialServiceActivityTableFilters.tsx'
import { useAssetDetailContext } from '@/pages/assetdetail/AssetDetailContext.ts'
import assetServiceActivitySort from '@/pages/assetdetail/serviceActivity/assetServiceActivitySort.ts'
import { ServiceActivityPageSize } from '@/features/serviceactivity/ServiceActivityPageSize.ts'
import ServiceActivityTable from '@/pages/serviceActivity/shared/ServiceActivityTable.tsx'
import ServiceActivityCsvContextProvider from '@/pages/serviceActivity/ServiceActivityCsvContextProvider.tsx'
import { datedCsvFileName } from '@/helpers/ExportCsvHelpers.ts'
import { useHashState } from '@/routing/useHashState.ts'
import CommercialServiceActivityViewer from '@/pages/serviceActivity/commercial/CommercialServiceActivityViewer.tsx'
import {
  serviceActivityHashParamName,
  workOrderTabHashParamName,
} from '@/pages/serviceActivity/shared/serviceActivityHashParamNames.ts'

export default function CommercialAssetServiceActivityTab(): React.JSX.Element {
  const { assetMetadata: data } = useAssetDetailContext()
  const serviceActivityQuery = useCommercialAssetServiceActivityQuery(
    data.assetId,
    true
  )

  const [selectedActivityId, setSelectedActivityId] = useHashState(
    serviceActivityHashParamName,
    [workOrderTabHashParamName]
  )

  /*
   * Find the selected activity from the query data.
   * TODO: Remove this lookup once we fully migrate to SerViceCloud since TicketSummaryModal rendered in CommercialServiceActivityViewer is the only component who needs the full ServiceActivity Object
   */
  const selectedServiceActivity = useMemo(
    () =>
      selectedActivityId
        ? serviceActivityQuery.data?.data.find(
            sa => sa.id === selectedActivityId
          )
        : undefined,
    [serviceActivityQuery.data, selectedActivityId]
  )

  const columns = useCommercialServiceActivityTableColumns(PageName.Asset)
  const exportCsvColumns = useCommercialServiceActivityCsvColumns(
    PageName.Asset
  )
  const filterDefs = useCommercialServiceActivityTableFilters(PageName.Asset)

  const rows = serviceActivityQuery.data?.data
  const sortedRows = useMemo<CommercialServiceActivity[] | undefined>(
    () => rows?.sort(assetServiceActivitySort),
    [rows]
  )

  const tableModel = useTable(sortedRows, columns, {
    pageSize: ServiceActivityPageSize,
  })

  const handleClickRow = (row: Row<CommercialServiceActivity>) => {
    setSelectedActivityId(row.original.id)
  }

  const csvOptions = useMemo(() => {
    return {
      columns: exportCsvColumns,
      fileName: datedCsvFileName(`${data.assetName}_service_activity`),
    }
  }, [exportCsvColumns, data])

  return (
    <>
      <h2 className="tw-mb-6 tw-text-xl tw-font-bold tw-text-gray-900">
        Service Activity
      </h2>
      <ServiceActivityCsvContextProvider
        pageName={PageName.Asset}
        tableModel={tableModel}
        csvOptions={csvOptions}
      >
        <ServiceActivityTable
          tableModel={tableModel}
          isLoading={serviceActivityQuery.isLoading}
          isError={serviceActivityQuery.isError}
          filterDefs={filterDefs}
          handleClickRow={handleClickRow}
        />
      </ServiceActivityCsvContextProvider>
      {selectedServiceActivity && (
        <CommercialServiceActivityViewer
          selectedServiceActivity={selectedServiceActivity}
          setSelectedActivityId={setSelectedActivityId}
        />
      )}
    </>
  )
}
