import { createContext, Dispatch, SetStateAction, useContext } from 'react'
import { CaseCommunication, TranscriptEntry } from '@/generated'

export type SortType = 'LATEST_FIRST' | 'OLDEST_FIRST'
export type CaseCommunicationsContextType = {
  isAllExpanded?: boolean
  sortType: SortType
  toggleIsAllExpanded: () => void
  toggleSortType: () => void
  caseId: string
  transcriptData: TranscriptEntry[] | undefined
  selectedCommunication: CaseCommunication | null
  setSelectedCommunication: Dispatch<SetStateAction<CaseCommunication | null>>
}

export const CaseCommunicationsContext = createContext<
  CaseCommunicationsContextType | undefined
>(undefined)

export function useCaseCommunicationsContext() {
  const context = useContext(CaseCommunicationsContext)

  if (context === undefined) {
    throw new Error(
      'useCaseCommunicationsContext must be used within an CaseCommunicationsProvider'
    )
  }

  return context
}
