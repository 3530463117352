import { ReactNode, useMemo, useState } from 'react'
import {
  CaseCommunicationsContext,
  SortType,
} from '@/features/serviceactivity/detailsDrawer/communications/components/list/context.ts'
import { CaseCommunication, CaseCommunicationTypeEnum } from '@/generated'
import { useTranscriptQuery } from '@/api/CaseQueries.ts'

type Props = Readonly<{
  children: ReactNode
  caseId: string
}>

function getVendorCallKey(
  communicationType: 'EMAIL_MESSAGE' | 'VOICE_CALL' | 'SMS' | undefined,
  selectedCommunication: CaseCommunication | null
): string | undefined {
  if (communicationType === CaseCommunicationTypeEnum.VoiceCall) {
    return selectedCommunication?.voiceCallMetadata?.vendorCallKey ?? undefined
  }
  if (communicationType === CaseCommunicationTypeEnum.Sms) {
    return selectedCommunication?.smsMetadata?.vendorCallKey ?? undefined
  }
}

export default function CaseCommunicationProvider({ children, caseId }: Props) {
  const [isAllExpanded, setIsAllExpanded] = useState<boolean | undefined>(
    undefined
  )
  const [sortType, setSortType] = useState<SortType>('LATEST_FIRST')
  const [selectedCommunication, setSelectedCommunication] =
    useState<CaseCommunication | null>(null)

  const communicationType = selectedCommunication?.type
  const vendorCallKey = getVendorCallKey(
    communicationType,
    selectedCommunication
  )
  const { data: transcriptData } = useTranscriptQuery(caseId, vendorCallKey)

  const value = useMemo(
    () => ({
      isAllExpanded,
      sortType,
      toggleIsAllExpanded: () => {
        setIsAllExpanded(value => !value)
      },
      toggleSortType: () => {
        setSortType(value =>
          value === 'LATEST_FIRST' ? 'OLDEST_FIRST' : 'LATEST_FIRST'
        )
      },
      selectedCommunication,
      setSelectedCommunication,
      caseId,
      transcriptData,
    }),
    [
      isAllExpanded,
      sortType,
      selectedCommunication,
      setSelectedCommunication,
      caseId,
      transcriptData,
    ]
  )

  return (
    <CaseCommunicationsContext.Provider value={value}>
      {children}
    </CaseCommunicationsContext.Provider>
  )
}
