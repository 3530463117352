import { PropsWithChildren, ReactNode, useEffect, useState } from 'react'
import { Icon } from '@iconify/react/offline'
import iconClose from '@iconify/icons-heroicons/x-mark'
import { createPortal } from 'react-dom'

type Props = Readonly<
  PropsWithChildren<{
    onClose: () => void
    title: ReactNode
  }>
>
export default function ContainedDrawer({ onClose, children, title }: Props) {
  const [isOpen, setIsOpen] = useState(false)

  // Handle open
  useEffect(() => {
    // Small delay to ensure DOM is ready for the animation
    const timer = setTimeout(() => {
      setIsOpen(true)
    }, 50)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  const handleClose = () => {
    setIsOpen(false)
    // Wait for animation to complete before actually closing
    setTimeout(onClose, 500)
  }

  const portalContent = (
    <div data-testid="contained-drawer">
      {/* Backdrop overlay */}
      <div
        data-testid="contained-drawer-backdrop"
        className={`tw-fixed tw-inset-0 tw-z-modal tw-bg-black/40 tw-transition-opacity tw-duration-500 tw-ease-in-out ${
          isOpen ? 'tw-opacity-100' : 'tw-pointer-events-none tw-opacity-0'
        }`}
        onClick={handleClose}
        aria-hidden="true"
      />
      {/* Drawer */}
      <div
        className="tw-fixed tw-right-0 tw-z-modal tw-m-auto tw-h-[80vh] tw-w-3/4 tw-rounded-t-xl tw-border-t tw-bg-white tw-shadow-lg tw-transition-all tw-duration-500 tw-ease-in-out"
        style={{
          bottom: isOpen ? 0 : '-90%',
        }}
      >
        <div className="tw-flex tw-h-full tw-flex-col">
          <div className="tw-flex tw-items-center tw-justify-between tw-border-b tw-border-gray-200 tw-p-6">
            {title}
            <button onClick={handleClose} aria-label="Close transcript">
              <Icon icon={iconClose} className="tw-size-5" aria-hidden="true" />
            </button>
          </div>
          <div className="tw-overflow-y-auto">{children}</div>
        </div>
      </div>
    </div>
  )

  return createPortal(portalContent, document.body)
}
