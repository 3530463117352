import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  ClientProfileContext,
  ClientProfileContextValue,
} from '@/contexts/ClientProfileContext.ts'
import { UserAnalyticsContext } from '@/analytics/UserAnalyticsContext.ts'

export const clientProfileStorageKey = 'client-profile-id'

export function ClientProfileProvider(
  props: Readonly<React.PropsWithChildren>
): React.JSX.Element {
  const [activeClientProfileId, setActiveClientProfileId] = useState<
    string | undefined
  >(sessionStorage.getItem(clientProfileStorageKey) ?? undefined)
  const userAnalyticsContext = useContext(UserAnalyticsContext)

  useEffect(() => {
    if (activeClientProfileId) {
      sessionStorage.setItem(clientProfileStorageKey, activeClientProfileId)
      userAnalyticsContext?.addUserProperties({
        clientProfile: activeClientProfileId,
      })
    } else {
      sessionStorage.removeItem(clientProfileStorageKey)
    }
  }, [activeClientProfileId, userAnalyticsContext])

  const contextValue = useMemo(
    (): ClientProfileContextValue => ({
      activeClientProfileId: activeClientProfileId,
      setActiveClientProfileId: (id?: string) => {
        setActiveClientProfileId(id)
      },
    }),
    [activeClientProfileId]
  )

  return (
    <ClientProfileContext.Provider value={contextValue}>
      {props.children}
    </ClientProfileContext.Provider>
  )
}
