import SnapshotValue from '@/pages/serviceActivity/residential/snapShots/components/SnapshotValue.tsx'
import { ReactElement, ReactNode } from 'react'

type Props = {
  label: string
  icon: ReactNode | null
  children:
    | ReactElement<typeof SnapshotValue>
    | Array<ReactElement<typeof SnapshotValue>>
}

export default function SnapshotLine({
  label,
  icon,
  children,
}: Readonly<Props>) {
  return (
    <div
      data-testid="summary-line"
      className={`tw-flex tw-justify-between tw-text-sm tw-font-normal tw-text-gray-700`}
    >
      <div className="tw-w-3/4 tw-text-wrap">
        <div className="tw-flex tw-items-center tw-gap-2">
          {icon}
          {label}
        </div>
      </div>
      <div className="tw-flex tw-items-center tw-gap-2">{children}</div>
    </div>
  )
}
