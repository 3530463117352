import Header from '@/features/serviceactivity/detailsDrawer/communications/components/list/header/Header.tsx'
import { CaseCommunication } from '@/generated'
import { useCaseCommunicationsContext } from '@/features/serviceactivity/detailsDrawer/communications/components/list/context.ts'
import CaseCommunicationDisclosure from '@/features/serviceactivity/detailsDrawer/communications/components/item/CaseCommunicationDisclosure.tsx'
import ContainedDrawer from '@/components/drawer/contained/ContainedDrawer.tsx'
import Transcript from '@/features/serviceactivity/detailsDrawer/communications/components/item/body/transcript/Transcript.tsx'
import TranscriptDrawerTitle from '@/features/serviceactivity/detailsDrawer/communications/components/item/body/transcript/TranscriptDrawerTitle.tsx'
import EmptyState from '../../../../../../../assets/emptyState/communicationsEmptyState.svg?react'

export type Props = Readonly<{
  items?: CaseCommunication[]
}>

export default function CaseCommunications({ items }: Props) {
  const { sortType, selectedCommunication, setSelectedCommunication } =
    useCaseCommunicationsContext()
  const list = items ? [...items] : []
  if (sortType !== 'LATEST_FIRST') {
    list.reverse()
  }
  return (
    <div className="tw-gap-4 tw-overflow-auto">
      <Header />
      <div>
        {list.length ? (
          list.map((item, index) => (
            <div key={`${item.timestamp}-${sortType}`}>
              <CaseCommunicationDisclosure item={item} index={index} />
            </div>
          ))
        ) : (
          <div className="tw-flex tw-flex-col tw-items-center tw-gap-2">
            <EmptyState />
            <div className="tw-text-base tw-font-medium tw-text-gray-700">
              No communication history to show.
            </div>
            <div className="tw-text-sm tw-font-normal tw-text-gray-500">
              Any communication related to this case will appear here.
            </div>
          </div>
        )}
        {/* Transcript overlay */}
        {!!selectedCommunication && (
          <ContainedDrawer
            title={<TranscriptDrawerTitle />}
            onClose={() => {
              setSelectedCommunication(null)
            }}
          >
            <Transcript />
          </ContainedDrawer>
        )}
      </div>
    </div>
  )
}
