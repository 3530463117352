import { useMemo } from 'react'
import { FilterDrawerFilterDef } from '@/components/core/drawers/filter/FiltersDrawerButton.tsx'
import { PageName, ResidentialServiceActivity } from '@/generated'
import {
  creationDateFilter,
  creationReasonFilter,
  pendingClientActionFilter,
  priorityFilter,
  serviceActivityPendingClientActionFilterDataAccessor,
  statusFilter,
  updatedDateFilter,
} from '@/features/serviceactivity/serviceActivityFilters.ts'
import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import { getAssetStageLabel } from '@/helpers/asset/AssetStageHelpers.ts'

export default function useResidentialServiceActivityTableFilters(
  pageName: PageName
) {
  const { serviceCloudIntegrationEnabled } = useFeatureFlags()
  return useMemo<
    Array<FilterDrawerFilterDef<ResidentialServiceActivity>>
  >(() => {
    const filters: FilterDrawerFilterDef<ResidentialServiceActivity>[] = []

    if (serviceCloudIntegrationEnabled) {
      filters.push({
        type: 'quick-filters',
        key: 'quick-filters',
        filters: [
          {
            key: 'pendingClientAction',
            label: 'Pending Client Action',
          },
          {
            key: 'isNonCoveredRequest',
            label: 'Non-Covered Service Request',
          },
        ],
      })
    }
    if (pageName == PageName.ServiceActivity) {
      filters.push({
        type: 'faceted',
        key: 'assetStage',
        header: 'Asset Lifecycle Stage',
        rowDataAccessor: (rowData: ResidentialServiceActivity) =>
          getAssetStageLabel(rowData.assetStage),
      })
    }
    filters.push(
      priorityFilter('priority', rowData => rowData.priority),
      creationReasonFilter(
        'creationReason',
        (rowData: ResidentialServiceActivity) => rowData.creationReason
      ),
      statusFilter(
        'status',
        rowData => rowData.status,
        serviceCloudIntegrationEnabled
      ),
      {
        type: 'faceted',
        key: 'type',
        header: serviceCloudIntegrationEnabled ? 'Case Type' : 'Ticket Type',
        rowDataAccessor: (rowData: ResidentialServiceActivity) => rowData.type,
      },
      {
        type: 'faceted',
        key: 'serviceType',
        header: 'Service Type',
        rowDataAccessor: (rowData: ResidentialServiceActivity) =>
          rowData.serviceType,
      },
      creationDateFilter('createdAt'),
      updatedDateFilter('updatedAt')
    )
    if (!serviceCloudIntegrationEnabled) {
      filters.push(
        pendingClientActionFilter(
          'pendingClientAction',
          serviceActivityPendingClientActionFilterDataAccessor
        )
      )
    }

    return filters
  }, [serviceCloudIntegrationEnabled, pageName])
}
