import ReactDOM from 'react-dom/client'
import { App } from './App.tsx'
import 'react-toastify/dist/ReactToastify.min.css'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { Slide, ToastContainer } from 'react-toastify'
import * as Sentry from '@sentry/react'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      gcTime: 30_000, // default garbage collection time 5 minutes
      staleTime: Infinity, // default disable automatic refresh
      refetchOnWindowFocus: false,
    },
  },
  // add a handler to report failed queries to Sentry
  queryCache: new QueryCache({
    onError: error => Sentry.captureException(error),
  }),
})

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const rootElement = document.getElementById('app')!
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    <QueryClientProvider client={queryClient}>
      <BrowserRouter
        future={{ v7_startTransition: true, v7_relativeSplatPath: true }}
      >
        <App />
        <ToastContainer transition={Slide} />
      </BrowserRouter>
    </QueryClientProvider>
  )
}
