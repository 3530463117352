import { BaseServiceActivity } from '@/generated'
import CaseDetailsDrawer from '@/features/serviceactivity/detailsDrawer/CaseDetailsDrawer.tsx'
import { Portal } from '@headlessui/react'
import TicketSummaryModal from '@/components/modals/ticket-summary-modal/TicketSummaryModal.tsx'

type Props = Readonly<{
  selectedServiceActivity: BaseServiceActivity
  setSelectedActivityId: (id: string | null) => void
}>

export default function CommercialServiceActivityViewer({
  selectedServiceActivity,
  setSelectedActivityId,
}: Props) {
  if (selectedServiceActivity.workOrdersCount !== null) {
    return (
      <CaseDetailsDrawer
        serviceActivityId={selectedServiceActivity.id}
        onClose={() => {
          setSelectedActivityId(null)
        }}
      />
    )
  }

  return (
    <Portal>
      <TicketSummaryModal
        show={true}
        serviceActivity={selectedServiceActivity}
        onClose={() => {
          setSelectedActivityId(null)
        }}
      />
    </Portal>
  )
}
