import { Badge } from '@/components/core/badges/Badge.tsx'
import { Icon } from '@iconify/react'
import { WorkOrderQuoteStatusEnum } from '@/generated'
import statusMap from '@/features/serviceactivity/detailsDrawer/workOrders/quotes/components/header/status/statusMap.ts'

export type Props = Readonly<{
  status?: WorkOrderQuoteStatusEnum
}>

export default function QuoteStatus({ status }: Props) {
  if (!status) {
    return null
  }
  const config = statusMap.get(status)
  if (!config) {
    return null
  }
  return (
    <Badge color={config.color}>
      <Icon icon={config.icon} className="tw-mr-1" />
      <span className="tw-font-medium">{config.label}</span>
    </Badge>
  )
}
