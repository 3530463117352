import { Icon } from '@iconify/react'
import iconCalendar from '@iconify/icons-material-symbols/calendar-month'
import { formatLocalDate } from '@/helpers/NativeDateTimeHelpers.ts'

export type Props = Readonly<{
  date: string | null
}>
export default function ExpirationDate({ date }: Props) {
  return date ? (
    <div className="tw-flex tw-items-center">
      <Icon icon={iconCalendar} className="tw-mr-2 tw-size-4" />
      <span className="tw-font-medium tw-text-gray-900">
        Expiration Date:&nbsp;
      </span>
      <span className="tw-text-gray-700">{formatLocalDate(date)}</span>
    </div>
  ) : null
}
