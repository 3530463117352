import { createContext, useContext } from 'react'

/**
 * Context that provides a Sentry initialization state and simplifies mocking for tests/stories.
 */
export enum SentryInitializationState {
  PreInitialization = 'PreInitialization',
  Initialized = 'Initialized',
  WontInitialize = 'WontInitialize',
}

export type SentryContextValue = {
  initializationState: SentryInitializationState
}

export const SentryContext = createContext<SentryContextValue | null>(null)

export function useSentryContext(): SentryContextValue {
  const context = useContext(SentryContext)
  if (!context) {
    throw new Error('No SentryContext available')
  }
  return context
}
