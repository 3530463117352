import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useAuth } from '@/components/auth/AuthHooks.ts'
import { z } from 'zod'

const actuatorQueryKeys = {
  info: 'info',
}

export interface ActuatorInfo {
  sentry: {
    dsn?: string
    environment?: string
  }
  git: {
    commit: {
      id: {
        abbrev?: string
      }
    }
  }
}

export function useActuatorInfoQuery(): UseQueryResult<ActuatorInfo> {
  const auth = useAuth()
  const actuatorInfoSchema = z.object({
    sentry: z.object({
      dsn: z.string(),
      environment: z.string(),
    }),
    git: z.object({
      commit: z.object({
        id: z.object({
          abbrev: z.string(),
        }),
      }),
    }),
  })

  return useQuery({
    queryKey: [actuatorQueryKeys.info, auth.accessToken],

    queryFn: async ({ signal }) => {
      return fetch(`${window.location.origin}/actuator/info`, {
        signal,
        headers: new Headers({
          Authorization: 'Bearer ' + (auth.accessToken ?? ''),
        }),
      })
        .then(r => {
          if (!r.ok) {
            throw new Error('Network response for /actuator/info was not ok')
          }
          return r.json()
        })
        .then(json => {
          const { success, data: actuatorInfo } =
            actuatorInfoSchema.safeParse(json)

          if (!success) {
            throw new Error('Unable to parse JSON from /actuator/info')
          }

          return actuatorInfo
        })
    },
    enabled: auth.isAuthenticated && auth.accessToken !== undefined,
    gcTime: Infinity,
    staleTime: Infinity,
  })
}
