import { Dispatch, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

type Value = string | null
export type HashState = [value: Value, setValue: Dispatch<Value>]

export function useHashState(
  paramName: string,
  childrenParamNames: string[] = []
): HashState {
  const location = useLocation()
  const navigate = useNavigate()

  const getHashParams = useCallback(() => {
    const hash = location.hash.slice(1) // Remove the # symbol
    return new URLSearchParams(hash)
  }, [location.hash])

  const getHashValue = useCallback(() => {
    const params = getHashParams()
    return params.get(paramName)
  }, [paramName, getHashParams])

  /**
   * The internal state in this hook serves multiple purposes:
   *
   * 1. Provides a React-friendly interface to URL hash parameters
   *    Components using this hook can treat hash parameters like normal React state
   *    through the returned value/setter pattern.
   *
   * 2. Prevents render inconsistencies
   *    Direct manipulation of the URL without state management leads to
   *    timing issues where component state doesn't match URL state during React's rendering phases.
   *
   * 3. Ensures consistency across navigation events
   *    When browser navigation occurs (back/forward), the internal state
   *    synchronizes automatically, preventing stale data in consuming components.
   *
   * 4. Optimizes performance
   *    Prevents unnecessary URL parsing on every render cycle by storing the
   *    current value in state and only updating when the URL actually changes.
   */
  const [value, setValue] = useState<string | null>(getHashValue())

  // Clear hash value
  const clearValue = useCallback(() => {
    const params = getHashParams()
    params.delete(paramName)
    // Remove all child hashes since the parent hash is removed
    childrenParamNames.forEach(childHash => {
      params.delete(childHash)
    })
    navigate({ hash: params.toString() }, { replace: true })
  }, [childrenParamNames, paramName, navigate, getHashParams])

  // Update hash value
  const updateValue = useCallback(
    (newValue: string | null) => {
      if (!newValue) {
        clearValue()
        return
      }
      const params = getHashParams()
      params.set(paramName, newValue)
      navigate({ hash: params.toString() }, { replace: true })
    },
    [paramName, navigate, getHashParams, clearValue]
  )

  // Sync state with URL when hash changes
  useEffect(() => {
    const newValue = getHashValue()
    if (value !== newValue) {
      setValue(newValue)
    }
  }, [location.hash, getHashValue, value])

  return [value, updateValue]
}
