import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import { useAssetMetadataQuery } from '@/api/AssetQueries.ts'
import { AssetDetailLink } from '@/pages/assetdetail/AssetDetailLink.tsx'
import SensitiveText from '@/privacy/SensitiveText.tsx'

export type CaseAssetDetailsProps = {
  assetId: number
}

export default function CaseAssetDetails({
  assetId,
}: Readonly<CaseAssetDetailsProps>) {
  const { isResidentialClient } = useFeatureFlags()
  const assetMetadataQuery = useAssetMetadataQuery(assetId)
  if (!assetMetadataQuery.isSuccess) {
    return
  }
  const { assetName, homeOwner, clientAssetId } = assetMetadataQuery.data
  return (
    <div className="tw-text-sm tw-text-gray-500">
      {isResidentialClient ? (
        <>
          <div className="tw-font-medium">
            Customer:{' '}
            <AssetDetailLink assetId={assetId}>
              <SensitiveText>{homeOwner.name}</SensitiveText>
            </AssetDetailLink>
          </div>
          <div className="tw-inline-flex tw-gap-2">
            <span>Client Asset ID: {clientAssetId}</span>
            <span>{'\u2022'}</span>
            <span>Omnidian Asset ID: {assetId}</span>
          </div>
        </>
      ) : (
        <div className="tw-font-medium">
          Asset:&nbsp;
          <AssetDetailLink assetId={assetId}>
            <SensitiveText>{assetName}</SensitiveText>
          </AssetDetailLink>
        </div>
      )}
    </div>
  )
}
