import {
  AccessorFn,
  CellContext,
  ColumnDef,
  FilterFnOption,
} from '@tanstack/react-table'
import { ReactNode } from 'react'
import { NestedKeyOf } from '@/helpers/TypeHelpers.ts'
import TwoValueTableElement from '@/pages/serviceActivity/residential/tableComponents/TwoValueTableElement.tsx'
import getNestedValue from '@/helpers/getNestedValue.ts'

type TwoValueColumnDef<T> = {
  accessorKey: NestedKeyOf<T>
  header: string | ReactNode
  render?: (ctx: Readonly<CellContext<T, unknown>>) => string | ReactNode
}

type TwoValuesColumnProps<T> = {
  id?: string
  primary: TwoValueColumnDef<T>
  secondary: TwoValueColumnDef<T>
  accessorFn?: AccessorFn<T>
  enableSorting?: boolean
  filterFn?: FilterFnOption<T>
}
export default function twoValuesColumn<T>({
  id,
  primary,
  secondary,
  accessorFn,
  enableSorting = false,
  filterFn,
}: Readonly<TwoValuesColumnProps<T>>): ColumnDef<T> {
  return {
    id: id ?? `${primary.accessorKey}:${secondary.accessorKey}`,
    accessorFn:
      accessorFn ??
      (originalRow =>
        `${getNestedValue(originalRow, primary.accessorKey)} ${getNestedValue(originalRow, secondary.accessorKey)}`),
    header: () => (
      <TwoValueTableElement
        primary={primary.header}
        secondary={secondary.header}
      />
    ),
    cell: (ctx: Readonly<CellContext<T, unknown>>) => {
      return (
        <TwoValueTableElement
          primary={
            primary.render?.(ctx) ??
            getNestedValue(ctx.row.original, primary.accessorKey)
          }
          secondary={
            secondary.render?.(ctx) ??
            getNestedValue(ctx.row.original, secondary.accessorKey)
          }
        />
      )
    },
    enableSorting,
    filterFn,
  }
}
