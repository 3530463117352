import { DateTimeFormat } from '@/helpers/NativeDateTimeHelpers.ts'
import VerticalPropertyItem from '@/features/serviceactivity/detailsDrawer/VerticalPropertyItem.tsx'
import { Case } from '@/generated'
import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import { calcDaysBetween } from '@/helpers/MomentHelpers.ts'
import { pluralize } from '@/helpers/StringHelpers.ts'
import CaseAssetDetails from '@/features/serviceactivity/detailsDrawer/CaseAssetDetails.tsx'
import CaseBanners from '@/features/serviceactivity/detailsDrawer/CaseBanners.tsx'
import TruncatedText from '@/components/text/TruncatedText.tsx'
import SensitiveText from '@/privacy/SensitiveText.tsx'
import CaseDetailsContentTabs from '@/features/serviceactivity/detailsDrawer/CaseDetailsContentTabs.tsx'

export type CaseDetailsContentProps = {
  caseData: Case
}

export default function CaseDetailsContent({
  caseData,
}: Readonly<CaseDetailsContentProps>) {
  const {
    caseNumber,
    assetId,
    subject,
    creationReason,
    closedAt,
    createdAt,
    updatedAt,
    origin,
    lastPublicOutreachDate,
    summary,
    caseType,
    rmaCaseNumber,
  } = caseData
  const { isResidentialClient } = useFeatureFlags()
  const lastPublicOutreachDays =
    lastPublicOutreachDate && calcDaysBetween(lastPublicOutreachDate)
  return (
    <div>
      <div className="tw-mb-10 tw-flex tw-flex-col tw-gap-8 tw-bg-white">
        <CaseBanners caseData={caseData} />
        <div className="tw-flex tw-items-end tw-justify-between">
          <div>
            <h2 className="tw-text-xl tw-font-medium tw-text-gray-900">
              <SensitiveText>{subject}</SensitiveText>
            </h2>
            {assetId && <CaseAssetDetails assetId={assetId} />}
          </div>
          <div className="tw-grow tw-text-right tw-text-sm tw-text-gray-500">
            <div>
              Last Updated: {DateTimeFormat.format(new Date(updatedAt))}
            </div>
            <div>Created: {DateTimeFormat.format(new Date(createdAt))}</div>
          </div>
        </div>
        <div className="tw-flex tw-items-start tw-gap-6">
          <VerticalPropertyItem label="Case Number">
            {caseNumber}
          </VerticalPropertyItem>

          <VerticalPropertyItem label="Creation Reason">
            {creationReason}
          </VerticalPropertyItem>

          {isResidentialClient && (
            <>
              <VerticalPropertyItem label="Case Type">
                {caseType}
              </VerticalPropertyItem>
              <VerticalPropertyItem label="Case Origin">
                {origin}
              </VerticalPropertyItem>
            </>
          )}

          <VerticalPropertyItem label="Days Open">
            {calcDaysBetween(createdAt, closedAt ?? undefined)} days
          </VerticalPropertyItem>

          {isResidentialClient && (
            <VerticalPropertyItem label="Last Customer Outreach">
              {lastPublicOutreachDays &&
                `${lastPublicOutreachDays} ${pluralize(lastPublicOutreachDays, 'day', 'days')} ago`}
            </VerticalPropertyItem>
          )}
        </div>
        {rmaCaseNumber && (
          <div className="tw-text-gray-700">
            <span className="tw-font-medium">RMA Claim Number: </span>
            {rmaCaseNumber}
          </div>
        )}
        {isResidentialClient && summary && (
          <TruncatedText
            lineCount={2}
            className="tw-whitespace-pre-wrap tw-text-gray-500"
          >
            <span className="tw-font-medium tw-text-gray-700">Summary: </span>
            <SensitiveText>{summary}</SensitiveText>
          </TruncatedText>
        )}
      </div>
      <CaseDetailsContentTabs caseData={caseData} />
    </div>
  )
}
