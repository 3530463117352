import SolarAssetsImage from '../assets/solar-assets.svg?react'
import PageBody from '@/pages/components/PageBody.tsx'

export default function NotFoundPage() {
  return (
    <PageBody bgColor="gray">
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <SolarAssetsImage />
        <div>Sorry, we could not find that page.</div>
      </div>
    </PageBody>
  )
}
