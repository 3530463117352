import DrawerWithAsyncContent from '@/components/drawer/DrawerWithAsyncContent'
import ServiceCloudContent from '@/features/serviceactivity/detailsDrawer/CaseDetailsContent.tsx'
import serviceActivityDrawerErrorText from '@/features/serviceactivity/detailsDrawer/serviceActivityDrawerErrorText.ts'
import { useCaseQuery } from '@/api/CaseQueries.ts'
import { Case } from '@/generated'
import { Badge } from '@/components/core/badges/Badge.tsx'
import {
  caseStatusBadgeColor,
  priorityToBadgeColor,
} from '@/helpers/ServiceActivityHelper.ts'
import { Drawer } from '@/components/core/drawers/Drawer'
import { Tooltip } from '@/components/core/tooltip/Tooltip.tsx'

type Props = Readonly<{
  serviceActivityId: string
  onClose: () => void
}>

export default function CaseDetailsDrawer({
  serviceActivityId,
  onClose,
}: Props) {
  const query = useCaseQuery(serviceActivityId)

  return (
    <DrawerWithAsyncContent
      errorText={serviceActivityDrawerErrorText}
      onClose={onClose}
      isPending={query.isPending}
      isError={query.isError}
      headerContent={
        <CaseDetailsHeader caseData={query.data} onClose={onClose} />
      }
    >
      {query.isSuccess && <ServiceCloudContent caseData={query.data} />}
    </DrawerWithAsyncContent>
  )
}

export type CaseDetailsHeaderProps = Readonly<{
  onClose: () => void
  caseData?: Case
}>

function CaseDetailsHeader({ onClose, caseData }: CaseDetailsHeaderProps) {
  return (
    <Drawer.Header onClose={onClose} className="tw-px-12">
      {caseData && (
        <div className="tw-flex tw-items-center tw-gap-4">
          <Tooltip content="Case Priority">
            <Badge color={priorityToBadgeColor(caseData.priority)} size="large">
              {caseData.priority}
            </Badge>
          </Tooltip>
          {caseData.status && (
            <Tooltip content="Case Status">
              <Badge color={caseStatusBadgeColor(caseData)} size="large">
                {caseData.status}
              </Badge>
            </Tooltip>
          )}
        </div>
      )}
    </Drawer.Header>
  )
}
