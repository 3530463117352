import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react'
import QuoteStatus from '@/features/serviceactivity/detailsDrawer/workOrders/quotes/components/header/status/QuoteStatus.tsx'
import { Icon } from '@iconify/react'
import chevronDown from '@iconify/icons-heroicons/chevron-down'
import Divider from '@/components/layout/Divider.tsx'
import QuoteCosts from '@/features/serviceactivity/detailsDrawer/workOrders/quotes/components/body/client/QuoteCosts.tsx'
import ExpirationDate from '@/features/serviceactivity/detailsDrawer/workOrders/quotes/components/body/client/expiration-date/ExpirationDate.tsx'
import { WorkOrderQuote } from '@/generated'

export type Props = Readonly<{
  quote: WorkOrderQuote
  isSingleQuote: boolean
}>

export default function QuoteDisclosure({ quote, isSingleQuote }: Props) {
  return (
    <Disclosure
      key={quote.id}
      defaultOpen={isSingleQuote}
      as="div"
      className="tw-flex tw-flex-col tw-gap-6 tw-rounded-xl tw-border tw-border-gray-200 tw-p-6"
    >
      {({ open }) => (
        <>
          <DisclosureButton
            disabled={isSingleQuote}
            className="tw-group tw-flex tw-w-full tw-items-center tw-justify-between"
          >
            <h3 className="tw-text-lg tw-font-medium tw-text-gray-900">
              {open && 'Quote Details'}
              {!open && (
                <div className="tw-flex tw-flex-col tw-items-start">
                  <div className="tw-text-lg tw-font-bold tw-text-gray-700">
                    {quote.clientTotalCost}
                  </div>
                  <div className="tw-text-xs tw-text-gray-400">{quote.id}</div>
                </div>
              )}
            </h3>
            <div className="tw-flex tw-gap-2">
              <QuoteStatus status={quote.status} />
              {!isSingleQuote && (
                <Icon
                  icon={chevronDown}
                  className="tw-size-5 tw-fill-white/60 group-data-[open]:tw-rotate-180 group-data-[hover]:tw-fill-white/50"
                />
              )}
            </div>
          </DisclosureButton>
          <DisclosurePanel className="tw-flex tw-flex-col tw-gap-6">
            <div className="tw-text-2xl tw-font-bold tw-text-gray-900">
              {quote.clientTotalCost}
            </div>
            <Divider />
            <QuoteCosts quote={quote} />
            <Divider />
            <ExpirationDate date={quote.expirationDate} />
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  )
}
