import { Link, LinkProps } from 'react-router-dom'
import { twMerge } from '@/helpers/CustomTwMerge.ts'

export function StyledLink({
  className,
  children,
  ...rest
}: Readonly<LinkProps>) {
  return (
    <Link
      onClick={e => {
        // If we're navigating away, generally there should be no need for other event handlers to run.
        // Specifically, this was added for use cases where a link is rendered inside of a larger element (say, a
        // table row) that also navigates the user (e.g. by updating the hash, opening a modal). stopPropagation
        // ensures these two URL updates aren't competing with each other.
        e.stopPropagation()
      }}
      className={twMerge(
        'tw-font-bold tw-text-gray-900 hover:tw-text-blue-600 hover:tw-underline',
        className
      )}
      {...rest}
    >
      {children}
    </Link>
  )
}
