// Helper function to access nested properties
export default function getNestedValue(obj: unknown, key: string): string {
  return (
    (key.split('.').reduce<unknown>((acc, part) => {
      if (acc && typeof acc === 'object' && part in acc) {
        return acc[part as keyof typeof acc]
      }
      return undefined
    }, obj) as string) || ''
  )
}
