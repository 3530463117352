import { useAssetDetailContext } from '@/pages/assetdetail/AssetDetailContext.ts'
import { Badge } from '@/components/core/badges/Badge.tsx'
import iconLocation from '@iconify/icons-material-symbols/location-on'
import { Icon } from '@iconify/react/offline'
import { NavTab, NavTabs } from '@/components/core/nav/NavTabs.tsx'
import {
  AssetDetailsTabRoute,
  AssetServiceActivityTabRoute,
} from '@/pages/assetdetail/AssetDetailRoutes.tsx'
import { Outlet } from 'react-router-dom'
import { AssetStage } from '@/generated'
import SensitiveText from '@/privacy/SensitiveText.tsx'
import PageBody from '@/pages/components/PageBody.tsx'

export default function AssetDetailPage(): React.JSX.Element {
  return (
    <PageBody bgColor="white">
      <div className="tw-flex tw-flex-col tw-gap-8">
        <AssetDetailHeader />
        <AssetDetailNavTabs />
        <Outlet />
      </div>
    </PageBody>
  )
}

const stageToLabelMap = new Map([
  [AssetStage.MetadataVerification, 'Metadata Verification'],
  [AssetStage.Pvt, 'Performance Verification'],
  [AssetStage.Active, 'Active'],
])

function AssetDetailHeader() {
  const {
    assetMetadata: { assetName, assetStage, address },
  } = useAssetDetailContext()

  return (
    <div>
      <div className="tw-mb-4 tw-inline-flex">
        {assetStage && (
          <Badge color="blue">{stageToLabelMap.get(assetStage)}</Badge>
        )}
      </div>
      <div className="tw-mb-2 tw-text-2xl tw-font-bold">
        <SensitiveText>{assetName}</SensitiveText>
      </div>
      <div className="tw-inline-flex tw-gap-2">
        <Icon icon={iconLocation} className="tw-size-6" />
        <SensitiveText className="tw-text-gray-700">
          {address.street}, {address.city}, {address.state},{' '}
          {address.postalCode}
        </SensitiveText>
      </div>
    </div>
  )
}

function AssetDetailNavTabs() {
  return (
    <NavTabs>
      <NavTab title="Details" path={AssetDetailsTabRoute} />
      <NavTab title="Service Activity" path={AssetServiceActivityTabRoute} />
    </NavTabs>
  )
}
