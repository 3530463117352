import Button from '@/components/core/buttons/Button.tsx'
import { useCaseCommunicationsContext } from '@/features/serviceactivity/detailsDrawer/communications/components/list/context.ts'
import { CaseCommunication, CaseCommunicationTypeEnum } from '@/generated'

export type DetailItem = {
  label: string
  value: string
}

export type Props = Readonly<{
  communication: CaseCommunication
  details: DetailItem[]
}>

export default function CasePhoneCommunicationContent({
  communication,
  details,
}: Props) {
  const { setSelectedCommunication } = useCaseCommunicationsContext()

  const buttonText =
    communication.type === CaseCommunicationTypeEnum.VoiceCall
      ? 'View Call Transcript'
      : 'View Text Conversation'
  return (
    <div className="tw-space-y-2 tw-text-sm tw-text-gray-700">
      {details.map(({ label, value }) => (
        <div key={label} className="tw-flex tw-items-center">
          <span className="tw-min-w-32 tw-font-medium tw-text-gray-500">
            {label}
          </span>
          <span className="tw-text-gray-900">{value}</span>
        </div>
      ))}
      <div className="tw-p-2">
        <Button
          size="xl"
          color="light"
          block
          onClick={() => {
            setSelectedCommunication(communication)
          }}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  )
}
