import { useAuthContext } from '@/contexts/auth/AuthContext.ts'
import { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import {
  ConsentContext,
  ConsentContextValue,
  ConsentTypes,
} from '@/consent/ConsentContext.ts'
import { useSPAConfigQuery } from '@/api/SpaQueries.ts'

// Sourced from https://support.termly.io/hc/en-us/articles/30710442081553-Getting-Consent-State-and-Handling-Consent-Changes-with-Termly
declare global {
  interface Window {
    Termly: {
      getConsentState: () => TermlyConsentCategories | undefined
      on: (event: 'initialized' | 'consent', cb: () => void) => void
    }
  }
}

// Other categories exist, just enumerating ones we need. See
// https://support.termly.io/hc/en-us/articles/30710442081553-Getting-Consent-State-and-Handling-Consent-Changes-with-Termly
type TermlyConsentCategories = {
  analytics: boolean
}

let termlyScriptAdded = false

export default function ConsentContextProvider({
  children,
}: Readonly<PropsWithChildren>) {
  const [contextValue, setContextValue] = useState<ConsentContextValue | null>(
    null
  )
  const { isAuthenticated } = useAuthContext()
  const { data: spaConfig } = useSPAConfigQuery()
  const checkConsentStatus = useCallback(() => {
    const consentCategories = window.Termly.getConsentState()
    const newConsents = new Map<ConsentTypes, boolean | null>([
      [ConsentTypes.Analytics, consentCategories?.analytics ?? null],
    ])
    setContextValue(prev => {
      // If any relevant consents were revoked, reload the page so consent will be re-evaluated prior to including
      // scripts.
      if (
        Object.values(ConsentTypes).find(
          t => !!prev?.consents.get(t) && !newConsents.get(t)
        )
      ) {
        window.location.reload()
      }
      return {
        consents: newConsents,
      }
    })
  }, [setContextValue])

  useEffect(() => {
    // As we require login to do anything on the portal, wait until the auth flow is completed.
    // Otherwise, the cookie banner could pop up while the user is getting redirected to log in.
    if (isAuthenticated && spaConfig && !termlyScriptAdded) {
      const termlyScript = document.createElement('script')
      termlyScript.src = `https://app.termly.io/resource-blocker/${spaConfig.termlyEnvironmentId}?autoBlock=on`
      termlyScript.async = true
      termlyScript.id = 'termly-script'
      termlyScript.onload = function () {
        window.Termly.on('consent', checkConsentStatus)
        checkConsentStatus()
      }
      document.head.prepend(termlyScript)
      termlyScriptAdded = true
    }
  }, [isAuthenticated, checkConsentStatus, spaConfig])
  return (
    <ConsentContext.Provider value={contextValue}>
      {children}
    </ConsentContext.Provider>
  )
}
