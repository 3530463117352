import { useAuth, useUser } from '@/components/auth/AuthHooks.ts'
import { Menu, MenuButton, MenuItems } from '@headlessui/react'
import { Avatar } from '@/components/core/avatar/Avatar.tsx'
import { twMerge } from '@/helpers/CustomTwMerge.ts'
import UserMenuItem from '@/components/layout/header/userMenu/UserMenuItem.tsx'

export function UserMenu() {
  const auth = useAuth()
  const user = useUser()

  const logout = async () => {
    await auth.signOut()
  }

  return (
    <Menu>
      {({ open }) => (
        <>
          <MenuButton
            data-testid="userMenuIcon"
            className="tw-border-0 tw-bg-transparent"
          >
            <Avatar fullName={user.name} />
          </MenuButton>
          {/**
           * We control display of the menu items ourselves (using the static attribute and inspecting the open render prop).
           * This is to ensure that when the menu is opened, ONLY the Cookie Settings menu item is rendered as a new DOM node,
           * which Termly's JS will properly detect. It ONLY looks at the added node, not any descendant nodes, so if
           * an entire DOM fragment gets added at once, the termly-display-preferences link can get lost and not have
           * the appropriate event handler attached.
           **/}
          <MenuItems
            data-testid="userMenu"
            static
            className={twMerge(
              'tw-absolute tw-right-0 tw-mt-2 tw-w-56 tw-origin-top-right tw-divide-y tw-divide-gray-100 tw-rounded-md tw-border tw-border-gray-200 tw-bg-white tw-shadow-lg tw-outline-none',
              open ? '' : 'tw-hidden'
            )}
          >
            <div className="tw-px-4 tw-py-3">
              <p className="tw-text-base tw-font-bold tw-leading-5 tw-text-black">
                {user.name}
              </p>
              <p className="tw-truncate tw-text-sm tw-font-medium tw-text-gray-700">
                {user.email}
              </p>
            </div>
            <div>
              {/**
               * Here we gate rendering of this MenuItem until the menu opens, which will ensure this DOM element
               * gets added by itself. See note above for why that is necessary with Termly.
               **/}
              {open && (
                <UserMenuItem className="termly-display-preferences">
                  Privacy Settings
                </UserMenuItem>
              )}
              <UserMenuItem
                onClick={() => {
                  void logout()
                }}
              >
                Logout
              </UserMenuItem>
            </div>
          </MenuItems>
        </>
      )}
    </Menu>
  )
}
