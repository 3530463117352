const LinkStyle = 'tw-text-xs tw-text-gray-700'

export default function PageFooter() {
  return (
    <footer className="tw-flex tw-justify-end tw-gap-4 tw-py-4">
      <a
        href="https://www.omnidian.com/privacy-policy/"
        target="_blank"
        className={LinkStyle}
        rel="noreferrer"
      >
        Privacy Policy
      </a>
      <a
        href="https://www.omnidian.com/your-privacy-choices/"
        target="_blank"
        className={LinkStyle}
        rel="noreferrer"
      >
        Your Privacy Choices
      </a>
    </footer>
  )
}
