import { CaseCommunication } from '@/generated'
import { DateTimeFormat } from '@/helpers/NativeDateTimeHelpers.ts'
import CasePhoneCommunicationContent, {
  DetailItem,
} from './CasePhoneCommunicationContent'

export type Props = Readonly<{
  communication: CaseCommunication
}>

export default function CaseSMSContent({ communication }: Props) {
  const { from, to, smsMetadata } = communication
  const start = smsMetadata?.sessionStartTime
    ? DateTimeFormat.format(new Date(smsMetadata.sessionStartTime))
    : '-'
  const end = smsMetadata?.sessionEndTime
    ? DateTimeFormat.format(new Date(smsMetadata.sessionEndTime))
    : '-'

  const details: DetailItem[] = [
    { label: 'From', value: from ?? '-' },
    { label: 'To', value: to ?? '-' },
    { label: 'Answered By', value: smsMetadata?.answeredBy ?? '-' },
    { label: 'Start', value: start },
    { label: 'End', value: end },
  ]

  return (
    <CasePhoneCommunicationContent
      communication={communication}
      details={details}
    />
  )
}
