import { WorkOrderQuoteStatusEnum } from '@/generated'
import { IconifyIcon } from '@iconify/react'
import { BadgeColor } from '@/components/core/badges/BadgeColor.ts'
import iconCheck from '@iconify/icons-material-symbols/check'
import iconCancel from '@iconify/icons-material-symbols/cancel-outline'
import iconTimer from '@iconify/icons-material-symbols/timer-outline'

const statusMap = new Map<
  WorkOrderQuoteStatusEnum,
  { label: string; icon: IconifyIcon; color: keyof typeof BadgeColor }
>([
  [
    WorkOrderQuoteStatusEnum.Approved,
    {
      label: 'Approved',
      icon: iconCheck,
      color: 'green',
    },
  ],
  [
    WorkOrderQuoteStatusEnum.Denied,
    {
      label: 'Denied',
      icon: iconCancel,
      color: 'gray',
    },
  ],
  [
    WorkOrderQuoteStatusEnum.PendingClientDecision,
    { label: 'Pending Approval', icon: iconTimer, color: 'red' },
  ],
  [
    WorkOrderQuoteStatusEnum.PendingCustomerAndClientDecision,
    {
      label: 'Pending Approval',
      icon: iconTimer,
      color: 'red',
    },
  ],
])

export default statusMap
