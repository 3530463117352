import NullTicketSummaryImage from '@/assets/emptyState/null-ticket-summary.svg?react'

type Props = Readonly<{
  summary?: string | null
}>
export default function ServiceActivitySummary({ summary }: Props) {
  if (!summary) {
    return <EmptyState />
  }
  return <div className="tw-whitespace-pre-line">{summary}</div>
}

function EmptyState() {
  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
      <NullTicketSummaryImage />
      <p className="tw-text-sm tw-font-medium tw-text-gray-500">
        No summary notes available for this ticket.
      </p>
    </div>
  )
}
