import { memo, ReactNode } from 'react'
import { Tooltip } from '@/components/core/tooltip/Tooltip.tsx'
import infoIcon from '@iconify/icons-material-symbols/info-outline'
import SnapshotIcon from '@/pages/serviceActivity/residential/snapShots/components/SnapshotIcon.tsx'

type Props = Readonly<{
  content: ReactNode | string
  'aria-label': string
}>

export const InfoTooltip = memo(function InfoTooltip({
  content,
  'aria-label': ariaLabel,
}: Props) {
  return (
    <Tooltip content={content}>
      <span role="tooltip" aria-label={ariaLabel}>
        <SnapshotIcon icon={infoIcon} />
      </span>
    </Tooltip>
  )
})
