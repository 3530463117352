import React, { useCallback, useEffect } from 'react'
import { LoadingPage } from '@/pages/LoadingPage.tsx'
import { flushLogEntries, useLogger } from '@/helpers/Logging.ts'
import { useAuthContext } from '@/contexts/auth/AuthContext.ts'
import { ErrorModal } from '@/components/error/ErrorModal.tsx'

/**
 * Ensures that the handleLoginRedirect() call only occurs once, even if this component re-renders (e.g. due to a
 * change in authContext). The redirect back from the Okta domain to complete login will always trigger a fresh
 * page/app load, so we should never need to execute it a second time.
 *
 * This follows the general guidelines for initialization code described in the React
 * docs: https://react.dev/learn/you-might-not-need-an-effect#initializing-the-application
 */
let handledRedirect = false

export const loginCallbackSessionStorageKey = 'login-callback-flag'
const loginCallbackSessionStorageValue = 'true'

export function LoginCallback(): React.JSX.Element {
  const logger = useLogger()
  const authContext = useAuthContext()

  const [displayError, setDisplayError] = React.useState<Error | null>(null)
  const doLoginRedirect = useCallback(
    async (authErrorMessage?: string) => {
      logger.info({
        message: `Redirect user back to login due to auth error: ${authErrorMessage ?? 'undefined'}`,
      })
      await flushLogEntries()
      await authContext.signInWithRedirect()
    },
    [logger, authContext]
  )

  const handleAuthError = useCallback(
    async (error: Error) => {
      const previouslyRestartedAuthFlow =
        sessionStorage.getItem(loginCallbackSessionStorageKey) ===
        loginCallbackSessionStorageValue
      /**
       * The flag in session storage tracks whether we have already re-attempted the login flow.
       * If we run into a subsequent error, we do not want to restart the flow again, as it may never be resolved.
       */
      if (previouslyRestartedAuthFlow) {
        setDisplayError(error)
      } else {
        sessionStorage.setItem(
          loginCallbackSessionStorageKey,
          loginCallbackSessionStorageValue
        )
        logger.info({
          message: `Redirect user back to login due to auth error: ${error.message}`,
        })
        await doLoginRedirect(error.message)
      }
    },
    [doLoginRedirect, logger]
  )

  useEffect(() => {
    if (!handledRedirect) {
      logger.info({ message: 'LoginCallback: Executing handleLoginRedirect' })
      authContext
        .handleLoginRedirect()
        .then(() => {
          sessionStorage.removeItem(loginCallbackSessionStorageKey)
        })
        .catch((e: unknown) => {
          void handleAuthError(e as Error)
        })
      handledRedirect = true
    }
  }, [authContext, logger, handleAuthError])

  useEffect(() => {
    if (authContext.authError) {
      void handleAuthError(authContext.authError)
    }
  }, [authContext.authError, handleAuthError])

  if (displayError) {
    return (
      <AuthErrorModal
        error={displayError}
        onRetry={() => void doLoginRedirect()}
      />
    )
  }
  return <LoadingPage />
}

interface AuthErrorModalProps {
  error: Error
  onRetry: () => void
}

function AuthErrorModal({
  error,
  onRetry,
}: Readonly<AuthErrorModalProps>): React.JSX.Element {
  // Clunky attempt to flush the log entries before the user clicks away and gets redirected to login again.
  // TODO: https://omnidian.atlassian.net/browse/HYP-2807 - If the endpoint was a POST, we could add an
  // onunload handler to the logger, to flush if the user leaves the page.
  useEffect(() => {
    void flushLogEntries()
  }, [])

  return <ErrorModal error={error} onRetry={onRetry} />
}
