import { PropsWithChildren } from 'react'
import { PageBodyBgColor } from '@/pages/components/PageBodyBgColor.ts'
import { twMerge } from '@/helpers/CustomTwMerge.ts'
import PageFooter from '@/pages/components/PageFooter.tsx'

export interface PageBodyProps extends PropsWithChildren {
  bgColor: keyof typeof PageBodyBgColor
}

export default function PageBody({
  bgColor = 'gray',
  children,
}: Readonly<PageBodyProps>) {
  const style = twMerge(
    'tw-flex tw-h-page tw-flex-col tw-justify-between tw-gap-8 tw-overflow-y-auto tw-px-8 tw-pt-8 2xl:tw-px-20',
    PageBodyBgColor[bgColor]
  )
  return (
    <div className={style}>
      {children}
      <PageFooter />
    </div>
  )
}
