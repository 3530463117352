import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useAuth } from '@/components/auth/AuthHooks.ts'
import { useClientProfileId } from '@/contexts/ClientProfileHooks.ts'
import { useAxiosClientConfig } from '@/helpers/AxiosHooks.ts'
import { useMemo } from 'react'
import {
  Case,
  CaseCommunication,
  CaseControllerApi,
  Configuration,
  WorkOrder,
  TranscriptEntry,
} from '@/generated'

function useCaseClient(): CaseControllerApi {
  const config = useAxiosClientConfig()
  return useMemo(() => {
    return new CaseControllerApi(new Configuration(config))
  }, [config])
}

const caseQueryKeys = {
  getCaseById: 'getCaseById',
  getWorkOrdersForCase: 'getWorkOrdersForCase',
  getCommunicationForCase: 'getCommunicationForCase',
  getTranscript: 'getTranscript',
}

export function useCaseQuery(caseId: string): UseQueryResult<Case> {
  const auth = useAuth()
  const client = useCaseClient()
  const clientProfileId = useClientProfileId()
  return useQuery({
    queryKey: [caseQueryKeys.getCaseById, caseId, clientProfileId],
    queryFn: async ({ signal }) => {
      const response = await client.getCaseById(caseId, clientProfileId, {
        signal,
      })
      return response.data
    },
    enabled: auth.isAuthenticated,
  })
}

export function useCaseWorkOrdersQuery(
  caseId: string
): UseQueryResult<Array<WorkOrder>> {
  const auth = useAuth()
  const client = useCaseClient()
  const clientProfileId = useClientProfileId()
  return useQuery({
    queryKey: [caseQueryKeys.getWorkOrdersForCase, caseId, clientProfileId],
    queryFn: async ({ signal }) => {
      const response = await client.getWorkOrdersForCase(
        caseId,
        clientProfileId,
        {
          signal,
        }
      )
      return response.data.data
    },
    enabled: auth.isAuthenticated,
  })
}

export function useCommunicationsQuery(
  caseId: string
): UseQueryResult<Array<CaseCommunication>> {
  const auth = useAuth()
  const client = useCaseClient()
  const clientProfileId = useClientProfileId()
  return useQuery({
    queryKey: [caseQueryKeys.getCommunicationForCase, caseId, clientProfileId],
    queryFn: async ({ signal }) => {
      const response = await client.getCommunicationsByCaseId(
        caseId,
        clientProfileId,
        {
          signal,
        }
      )
      return response.data.data
    },
    enabled: auth.isAuthenticated,
  })
}

export function useTranscriptQuery(
  caseId: string,
  vendorCallKey: string | null | undefined
): UseQueryResult<Array<TranscriptEntry>> {
  const auth = useAuth()
  const client = useCaseClient()
  const clientProfileId = useClientProfileId()
  return useQuery({
    queryKey: [
      caseQueryKeys.getTranscript,
      caseId,
      clientProfileId,
      vendorCallKey,
    ],
    queryFn: async ({ signal }) => {
      const response = await client.getTranscriptByCallKey(
        caseId,
        vendorCallKey as string,
        clientProfileId,
        {
          signal,
        }
      )
      return response.data.messages
    },
    enabled: auth.isAuthenticated && !!vendorCallKey,
  })
}
