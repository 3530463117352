import React, { PropsWithChildren, useEffect } from 'react'
import { useAuthContext } from '@/contexts/auth/AuthContext.ts'
import { flushLogEntries, useLogger } from '@/helpers/Logging.ts'
import { toRelativeUrl } from '@okta/okta-auth-js'

interface AuthenticatedProps extends PropsWithChildren {
  readonly redirectingMessage?: React.ReactNode
}

export function Authenticated(props: AuthenticatedProps): React.JSX.Element {
  const logger = useLogger()
  const authContext = useAuthContext()

  useEffect(() => {
    if (authContext.isAuthenticated === false) {
      logger.info({
        message: 'User is not authenticated, redirecting to sign in.',
      })
      // Force the log entries out the door prior to redirecting to Okta for log in.
      void flushLogEntries().finally(
        () =>
          void authContext.signInWithRedirect(
            toRelativeUrl(window.location.href, window.location.origin)
          )
      )
    }
  }, [logger, authContext])

  if (authContext.isAuthenticated) return <>{props.children}</>

  return <>{props.redirectingMessage}</>
}
