/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useMemo } from 'react'
import {
  Configuration,
  SpaControllerApi,
  SPAAppendLogRequest,
} from '@/generated'
import { AuthContext } from '@/contexts/auth/AuthContext.ts'

export type LogLevel = 'ERROR' | 'WARN' | 'INFO' | 'DEBUG'

type LogEntry = {
  level: LogLevel
  data: object
}

const BUFFER_TIMEOUT_MS = 5_000
const BUFFER_SIZE = 10
let logBuffer: LogEntry[] = []
let timeoutId: any = null

export async function flushLogEntries(accessToken?: string) {
  if (logBuffer.length === 0) return
  const payload: SPAAppendLogRequest = {
    entries: logBuffer,
  }
  logBuffer = []
  try {
    await new SpaControllerApi(
      new Configuration({
        basePath: window.location.origin,
        accessToken: accessToken ?? undefined,
      })
    ).appendLog(payload)
  } catch (e) {
    console.error(e)
  }
}

export function pushLogEntry(
  level: LogLevel,
  data: object,
  accessToken?: string
) {
  logBuffer.push({
    level,
    data: { ...data, _ts: new Date(), _path: window.location.pathname },
  })
  if (logBuffer.length >= BUFFER_SIZE) {
    void flushLogEntries(accessToken)
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      void flushLogEntries(accessToken)
    }, BUFFER_TIMEOUT_MS)
  }
}

export function useLogger() {
  // Explicitly call useContext here instead of useAuthContext, as it is possible the logger is being used outside
  // of the AuthProvider.
  const accessToken = useContext(AuthContext)?.accessToken

  return useMemo(
    () => ({
      error: (data: object) => {
        pushLogEntry('ERROR', data, accessToken)
      },
      warn: (data: object) => {
        pushLogEntry('WARN', data, accessToken)
      },
      info: (data: object) => {
        pushLogEntry('INFO', data, accessToken)
      },
      debug: (data: object) => {
        pushLogEntry('DEBUG', data, accessToken)
      },
    }),
    [accessToken]
  )
}
