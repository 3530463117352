import { ComponentProps } from 'react'
import { MenuItem } from '@headlessui/react'
import { twMerge } from '@/helpers/CustomTwMerge.ts'

export default function UserMenuItem({
  children,
  className,
  ...rest
}: Readonly<ComponentProps<'button'>>) {
  return (
    <MenuItem>
      <button
        {...rest}
        className={twMerge(
          'tw-flex tw-w-full tw-px-4 tw-py-2 tw-text-left tw-text-sm tw-text-gray-700 data-[focus]:tw-bg-gray-100 data-[focus]:tw-text-gray-900',
          className
        )}
      >
        {children}
      </button>
    </MenuItem>
  )
}
