import { useAssetDetailContext } from '@/pages/assetdetail/AssetDetailContext.ts'
import { useResidentialAssetServiceActivityQuery } from '@/api/AssetQueries.ts'
import { useMemo } from 'react'
import useResidentialServiceActivityTableColumns, {
  ResidentialServiceActivityTableColumnVisibility,
} from '@/pages/serviceActivity/residential/useResidentialServiceActivityTableColumns.tsx'
import useResidentialServiceActivityTableFilters from '@/pages/serviceActivity/residential/useResidentialServiceActivityTableFilters.tsx'
import assetServiceActivitySort from '@/pages/assetdetail/serviceActivity/assetServiceActivitySort.ts'
import useTable from '@/components/core/table/useTable.tsx'
import { Row } from '@tanstack/react-table'
import { PageName, ResidentialServiceActivity } from '@/generated'
import { ServiceActivityPageSize } from '@/features/serviceactivity/ServiceActivityPageSize.ts'
import ServiceActivityTable from '@/pages/serviceActivity/shared/ServiceActivityTable.tsx'
import useResidentialServiceActivityCsvColumns from '@/pages/serviceActivity/residential/useResidentialServiceActivityCsvColumns.ts'
import ServiceActivityCsvContextProvider from '@/pages/serviceActivity/ServiceActivityCsvContextProvider.tsx'
import { datedCsvFileName } from '@/helpers/ExportCsvHelpers.ts'
import ResidentialServiceActivityDrawer from '@/features/serviceactivity/detailsDrawer/ResidentialServiceActivityDrawer.tsx'
import { useHashState } from '@/routing/useHashState.ts'
import {
  serviceActivityHashParamName,
  workOrderTabHashParamName,
} from '@/pages/serviceActivity/shared/serviceActivityHashParamNames.ts'

export default function ResidentialAssetServiceActivityTab() {
  const { assetMetadata } = useAssetDetailContext()
  const { data, isLoading, isError } = useResidentialAssetServiceActivityQuery(
    assetMetadata.assetId,
    true
  )

  const [selectedActivityId, setSelectedActivityId] = useHashState(
    serviceActivityHashParamName,
    [workOrderTabHashParamName]
  )

  const sortedRows = useMemo(
    () => (data?.data ?? []).sort(assetServiceActivitySort),
    [data?.data]
  )

  const columns = useResidentialServiceActivityTableColumns(PageName.Asset)
  const filterDefs = useResidentialServiceActivityTableFilters(PageName.Asset)
  const tableModel = useTable(sortedRows, columns, {
    columnVisibility: ResidentialServiceActivityTableColumnVisibility,
    pageSize: ServiceActivityPageSize,
  })
  const exportCsvColumns = useResidentialServiceActivityCsvColumns()
  const csvOptions = useMemo(() => {
    return {
      columns: exportCsvColumns,
      fileName: datedCsvFileName(
        `${assetMetadata.homeOwner.name}_service_activity`
      ),
    }
  }, [exportCsvColumns, assetMetadata])

  const handleClickRow = (row: Row<ResidentialServiceActivity>) => {
    setSelectedActivityId(row.original.id)
  }

  return (
    <div>
      <ServiceActivityCsvContextProvider
        pageName={PageName.Asset}
        tableModel={tableModel}
        csvOptions={csvOptions}
      >
        <ServiceActivityTable
          tableModel={tableModel}
          isLoading={isLoading}
          isError={isError}
          filterDefs={filterDefs}
          handleClickRow={handleClickRow}
        />
      </ServiceActivityCsvContextProvider>

      {selectedActivityId && (
        <ResidentialServiceActivityDrawer
          serviceActivityId={selectedActivityId}
          onClose={() => {
            setSelectedActivityId(null)
          }}
          onChangeServiceActivityId={setSelectedActivityId}
        />
      )}
    </div>
  )
}
