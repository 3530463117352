import SensitiveText from '@/privacy/SensitiveText.tsx'
import { CellContext } from '@tanstack/react-table'
import { Maybe } from '@/helpers/TypeHelpers.ts'
import React from 'react'

export default function SensitiveTextCell<T>(
  ctx: Readonly<CellContext<T, unknown>>
): React.JSX.Element {
  return <SensitiveText>{ctx.getValue<Maybe<string>>()}</SensitiveText>
}
