import { useAuth } from '@/components/auth/AuthHooks'
import { useBrandingQuery } from '@/api/ApiQueries.ts'
import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import Button from '@/components/core/buttons/Button.tsx'
import { useContext, useState } from 'react'
import { ClientProfileSelectionModal } from '@/components/modals/clientprofile/ClientProfileSelectionModal.tsx'
import swapHoriz from '@iconify/icons-material-symbols/swap-horiz'
import { NavLink, useNavigate } from 'react-router-dom'
import { useClientProfileIdsQuery } from '@/api/SpaQueries.ts'
import Logo from '@/assets/omnidian-logo.svg?react'
import { twMerge } from '@/helpers/CustomTwMerge.ts'
import { ClientProfileContext } from '@/contexts/ClientProfileContext.ts'
import { UserMenu } from '@/components/layout/header/userMenu/UserMenu.tsx'

export function SiteHeader() {
  const auth = useAuth()
  const { data: branding } = useBrandingQuery()
  const { serviceActivityEnabled, portfolioOverviewEnabled } = useFeatureFlags()
  const navigate = useNavigate()

  const { activeClientProfileId, setActiveClientProfileId } =
    useContext(ClientProfileContext)
  const clientProfileIdsQuery = useClientProfileIdsQuery()
  const [showClientModal, setShowClientModal] = useState<boolean>(false)
  const clientProfileIds = clientProfileIdsQuery.data ?? []

  return (
    <div className="tw-z-[500] tw-flex tw-items-center tw-gap-4 tw-self-stretch tw-border-b tw-border-gray-200 tw-bg-white tw-px-8 tw-py-4 2xl:tw-px-20">
      <NavLink to="/">
        <Logo />
      </NavLink>
      {branding?.logoUrl && (
        <>
          <div className="tw-h-[32px] tw-w-0.5 tw-border-l tw-border-slate-300"></div>
          <NavLink to="/">
            <img
              alt={`${branding.name} Logo`}
              src={branding.logoUrl}
              className="tw-max-h-[32px] tw-max-w-[126px]"
            />
          </NavLink>
        </>
      )}
      <div className="tw-shrink tw-grow tw-basis-0"></div>
      <ul className="tw-inline-flex tw-gap-6">
        {portfolioOverviewEnabled && (
          <HeaderNavLink path="/portfolio" title="Portfolio Overview" />
        )}
        {serviceActivityEnabled && (
          <HeaderNavLink path="/service-activity" title="Service Activity" />
        )}
      </ul>
      {clientProfileIds.length > 1 && (
        <div>
          <Button
            icon={swapHoriz}
            color="light"
            onClick={() => {
              setShowClientModal(true)
            }}
          >
            Switch client
          </Button>
        </div>
      )}
      {showClientModal && (
        <ClientProfileSelectionModal
          clientProfileIds={clientProfileIds}
          onSetClientProfileId={clientProfileId => {
            setShowClientModal(false)
            if (activeClientProfileId !== clientProfileId) {
              setActiveClientProfileId(clientProfileId)
              navigate('/')
            }
          }}
          onClose={() => {
            setShowClientModal(false)
          }}
        />
      )}
      <div className="tw-cursor-default tw-select-none">
        {auth.isAuthenticated && <UserMenu />}
      </div>
    </div>
  )
}

interface HeaderNavLinkProps {
  title: string
  path: string
}

function HeaderNavLink({ title, path }: Readonly<HeaderNavLinkProps>) {
  return (
    <li className="tw-m-0 tw-cursor-pointer tw-select-none tw-list-none tw-p-0">
      <NavLink
        to={path}
        className={({ isActive }) =>
          twMerge(
            'tw-text-center tw-text-sm tw-font-medium',
            isActive
              ? 'tw-text-blue-600'
              : 'tw-text-gray-500 hover:tw-text-gray-900'
          )
        }
      >
        {title}
      </NavLink>
    </li>
  )
}
