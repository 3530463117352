import React, { PropsWithChildren, useMemo } from 'react'
import {
  SentryInitializationState,
  useSentryContext,
} from '@/o11y/SentryContext.ts'
import { Routes } from 'react-router-dom'
import * as Sentry from '@sentry/react'

export function SentryRoutes({
  children,
}: Readonly<PropsWithChildren>): React.ReactNode {
  const sentryContext = useSentryContext()

  const SentryRoutesInternal = useMemo(() => {
    if (
      sentryContext.initializationState ===
      SentryInitializationState.Initialized
    ) {
      return Sentry.withSentryReactRouterV6Routing(Routes)
    }

    // default to using ReactRouter.Routes until Sentry is initialized
    return Routes
  }, [sentryContext])

  return <SentryRoutesInternal>{children}</SentryRoutesInternal>
}
