import { Avatar } from '@/components/core/avatar/Avatar.tsx'
import { useCaseCommunicationsContext } from '@/features/serviceactivity/detailsDrawer/communications/components/list/context.ts'
import { DateFormat, DateTimeFormat } from '@/helpers/NativeDateTimeHelpers.ts'
import omnidianMark from '@/assets/icons/omnidian-mark.png'
import { CaseCommunicationTypeEnum } from '@/generated'

export default function TranscriptDrawerTitle() {
  const { selectedCommunication: communication, transcriptData } =
    useCaseCommunicationsContext()

  const isVoiceCall =
    communication?.type === CaseCommunicationTypeEnum.VoiceCall
  const isSms = communication?.type === CaseCommunicationTypeEnum.Sms

  const voiceCallStartTime =
    isVoiceCall && communication.timestamp
      ? `Call started: ${DateTimeFormat.format(new Date(communication.timestamp))}`
      : ''

  const smsTimeRange =
    isSms && transcriptData?.length
      ? `${DateFormat.format(
          new Date(transcriptData[transcriptData.length - 1]?.timeStamp ?? '')
        )} - ${DateFormat.format(new Date(transcriptData[0]?.timeStamp ?? ''))}`
      : ''

  const titleText = isVoiceCall ? 'Call Transcript' : 'Text Conversation'

  return (
    <div className="tw-flex tw-gap-2">
      <div className="tw-flex">
        <Avatar fullName="Omnidian Support" profileImageUrl={omnidianMark} />
        <div className="tw-z-0 -tw-ml-2 tw-flex tw-size-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-700 tw-ring-2">
          <span className="tw-text-sm tw-text-white">CU</span>
        </div>
      </div>

      <div className="tw-text-base tw-font-medium tw-text-gray-700">
        {titleText}
      </div>

      <div className="tw-text-base tw-font-normal tw-text-gray-500">
        {voiceCallStartTime}
        {smsTimeRange}
      </div>
    </div>
  )
}
