import { useLogger } from '@/helpers/Logging.ts'
import { Modal } from '@/components/core/modals/Modal.tsx'
import Button from '@/components/core/buttons/Button.tsx'
import * as React from 'react'

interface ErrorModalProps {
  error: unknown
  onRetry: () => void
}

/**
 * Display an error modal and report the error to our logging handler.
 * @param error
 * @param onRetry
 * @constructor
 */
export function ErrorModal({ error, onRetry }: Readonly<ErrorModalProps>) {
  const logger = useLogger()

  if (error instanceof Error) {
    logger.error({
      message: error.message,
      stack: error.stack,
    })
  } else {
    logger.error({ error })
  }

  return (
    <Modal show={true} onClose={onRetry}>
      <Modal.Header onClose={onRetry}>Oops, something went wrong!</Modal.Header>
      <Modal.Body>
        <div className="tw-rounded tw-bg-red-200 tw-p-6">
          An unexpected error has occurred. Try again or refresh your browser.
        </div>
      </Modal.Body>
      <Modal.Footer className="tw-justify-center">
        <Button onClick={onRetry}>Try again</Button>
      </Modal.Footer>
    </Modal>
  )
}

export function ErrorBoundaryModal(
  errorData: Readonly<{
    error: unknown
    componentStack: string
    eventId: string
    resetError(): void
  }>
): React.ReactElement {
  return <ErrorModal error={errorData.error} onRetry={errorData.resetError} />
}
