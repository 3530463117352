import { ComponentProps, PropsWithChildren } from 'react'

export default function SensitiveText({
  children,
  ...props
}: Readonly<PropsWithChildren<ComponentProps<'span'>>>) {
  return (
    <span {...props} data-heap-redact-text="true">
      {children}
    </span>
  )
}
