import { createContext, useContext } from 'react'

/**
 * Context that provides a facade around Okta Auth's state and simplifies mocking for tests/stories.
 */
export type AuthContextValue = {
  isAuthenticated?: boolean
  authError?: Error
  accessToken?: string
  userName?: string
  userEmail?: string
  signInWithRedirect: (originalUri?: string) => Promise<void>
  handleLoginRedirect: () => Promise<void>
  signOut: () => Promise<boolean>
}

export const AuthContext = createContext<AuthContextValue | null>(null)

export function useAuthContext(): AuthContextValue {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('No AuthContext available')
  }
  return context
}
