import { Icon } from '@iconify/react/offline'
import { IconifyIcon } from '@iconify/react'

type Props = Readonly<{
  icon: IconifyIcon
}>
export default function SnapshotIcon({ icon }: Props) {
  return (
    <Icon
      icon={icon}
      data-testid="descriptionIcon"
      className="tw-text-gray-500"
      width={16}
      height={16}
    />
  )
}
