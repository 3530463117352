import { createContext } from 'react'

export enum ConsentTypes {
  Analytics = 'Analytics',
}

export type ConsentContextValue = {
  consents: Map<ConsentTypes, boolean | null>
}

export const ConsentContext = createContext<ConsentContextValue | null>(null)
