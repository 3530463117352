import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import WorkOrdersPanel from '@/features/serviceactivity/detailsDrawer/workOrders/WorkOrdersPanel.tsx'
import CaseCommunicationsPanel from '@/features/serviceactivity/detailsDrawer/communications/CaseCommunicationsPanel.tsx'
import { PropsWithChildren } from 'react'
import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import { Case } from '@/generated'

type Props = Readonly<{
  caseData: Case
}>
export default function CaseDetailsContentTabs({ caseData }: Props) {
  const { id, workOrderCount } = caseData
  const showWorkOrdersTab = workOrderCount !== null && workOrderCount > 0
  const { communicationFeedEnabled } = useFeatureFlags()

  if (!showWorkOrdersTab && !communicationFeedEnabled) {
    return null
  }
  return (
    <div className="-tw-mx-12 tw-flex-1 tw-overflow-auto tw-bg-gray-50">
      <TabGroup className="tw-flex tw-h-full tw-flex-col">
        <TabList className="tw-flex tw-gap-2 tw-border-b tw-border-gray-200 tw-bg-white tw-px-12">
          {showWorkOrdersTab && (
            <CaseTab>
              <div className="tw-inline-flex tw-gap-1.5">
                Work Orders{' '}
                <span className="tw-flex tw-h-5 tw-min-w-5 tw-flex-col tw-items-center tw-justify-center tw-rounded-full tw-bg-blue-100 tw-p-1 tw-text-blue-600">
                  {workOrderCount}
                </span>
              </div>
            </CaseTab>
          )}
          {communicationFeedEnabled && (
            <CaseTab>Customer Communication</CaseTab>
          )}
        </TabList>
        <TabPanels className="tw-px-4 tw-pt-4">
          {showWorkOrdersTab && (
            <TabPanel>
              <WorkOrdersPanel caseId={id} />
            </TabPanel>
          )}
          {communicationFeedEnabled && (
            <TabPanel>
              <CaseCommunicationsPanel caseId={id} />
            </TabPanel>
          )}
        </TabPanels>
      </TabGroup>
    </div>
  )
}

function CaseTab({ children }: Readonly<PropsWithChildren>) {
  return (
    <Tab className="-tw-mb-[1px] tw-border-b tw-border-gray-200 tw-p-4 tw-text-sm tw-font-medium tw-text-gray-500 data-[selected]:tw-border-blue-600 data-[selected]:tw-text-blue-600">
      {children}
    </Tab>
  )
}
