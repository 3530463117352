import { Tooltip } from '@/components/core/tooltip/Tooltip.tsx'
import { Icon } from '@iconify/react/offline'
import infoIcon from '@iconify/icons-material-symbols/info-outline'
import useServiceActivityObjectName from '@/pages/serviceActivity/useServiceActivityObjectName.ts'

const DATA_REFRESH_TOOLTIP = (objectName: string) =>
  `${objectName} data auto-refreshes every 15 minutes. For the latest data, refresh your browser.`

export default function DataRefreshMessage() {
  const objectName = useServiceActivityObjectName()

  return (
    <div className="tw-flex tw-items-center tw-gap-2 tw-text-sm tw-font-normal tw-text-gray-500">
      <Tooltip
        content={
          <div className="tw-w-48">{DATA_REFRESH_TOOLTIP(objectName)}</div>
        }
      >
        <div className="tw-flex tw-gap-1">
          <Icon className="tw-size-5" icon={infoIcon} />
          Data refresh frequency
        </div>
      </Tooltip>
    </div>
  )
}
