import DOMPurify from 'dompurify'

export type Props = Readonly<{
  html: string
}>

export default function CaseEmailContent({ html }: Props) {
  const clean = DOMPurify.sanitize(html, {
    ALLOWED_TAGS: ['p', 'br', 'table', 'tr', 'td', 'span', 'div', 'img', 'a'],
    ALLOWED_ATTR: ['style', 'src', 'alt', 'title', 'target'],
    ALLOW_DATA_ATTR: false,
  })
  return (
    <div
      className="tw-text-gray-700"
      data-testid="case-email-content"
      dangerouslySetInnerHTML={{ __html: clean }}
    />
  )
}
