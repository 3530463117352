import { CaseCommunication } from '@/generated'
import formatDuration from '@/features/serviceactivity/detailsDrawer/communications/components/item/body/formatDuration.ts'
import CasePhoneCommunicationContent, {
  DetailItem,
} from './CasePhoneCommunicationContent.tsx'

export type Props = Readonly<{
  communication: CaseCommunication
}>

export default function CaseVoiceCallContent({ communication }: Props) {
  const { from, to, voiceCallMetadata } = communication
  const length = formatDuration(voiceCallMetadata?.duration)

  const details: DetailItem[] = [
    { label: 'From', value: from ?? '-' },
    { label: 'To', value: to ?? '-' },
    { label: 'Answered By', value: voiceCallMetadata?.answeredBy ?? '-' },
    { label: 'Length', value: length },
  ]

  return (
    <CasePhoneCommunicationContent
      communication={communication}
      details={details}
    />
  )
}
