import { ConsentContext, ConsentTypes } from '@/consent/ConsentContext.ts'
import { PropsWithChildren, useContext, useEffect, useState } from 'react'
import { useAuthContext } from '@/contexts/auth/AuthContext.ts'
import {
  UserAnalyticsContext,
  UserAnalyticsContextValue,
} from '@/analytics/UserAnalyticsContext.ts'
import { useSPAConfigQuery } from '@/api/SpaQueries.ts'

// Sourced from https://developers.heap.io/reference/client-side-apis-overview#typescript-type-definitions
declare global {
  interface Window {
    heap: {
      identify: (identity: string) => void
      addUserProperties: (properties: object) => void
      // This definition is not included in Heap's docs
      load: (environmentId: string) => void
    }
  }
}

let heapInitialized = false

export default function UserAnalyticsContextProvider({
  children,
}: Readonly<PropsWithChildren>) {
  const [contextValue, setContextValue] =
    useState<UserAnalyticsContextValue | null>(null)
  const { data: spaConfig } = useSPAConfigQuery()
  const authContext = useAuthContext()
  const consentContext = useContext(ConsentContext)
  useEffect(() => {
    if (
      !heapInitialized &&
      spaConfig &&
      authContext.userEmail &&
      consentContext?.consents.get(ConsentTypes.Analytics)
    ) {
      window.heap.load(spaConfig.heapEnvironmentId)
      window.heap.identify(authContext.userEmail)
      setContextValue({
        addUserProperties: window.heap.addUserProperties,
      })
      heapInitialized = true
    }
  }, [authContext, consentContext, spaConfig])
  return (
    <UserAnalyticsContext.Provider value={contextValue}>
      {children}
    </UserAnalyticsContext.Provider>
  )
}
