import moment from 'moment/moment'

export default function formatDuration(
  durationInSeconds: number | null | undefined
): string {
  if (durationInSeconds == null) return '-'
  if (durationInSeconds === 0) return '0 seconds'

  const duration = moment.duration(durationInSeconds, 'seconds')
  const hours = Math.floor(duration.asHours())
  const minutes = duration.minutes()
  const seconds = duration.seconds()

  const parts: string[] = []

  if (hours > 0) {
    parts.push(`${hours} ${hours === 1 ? 'hour' : 'hours'}`)
  }

  if (minutes > 0) {
    parts.push(`${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`)
  }

  if (seconds > 0 || parts.length === 0) {
    parts.push(`${seconds} ${seconds === 1 ? 'second' : 'seconds'}`)
  }

  return parts.join(', ')
}
