import { useMemo } from 'react'
import { ColumnDef, Row, VisibilityState } from '@tanstack/react-table'
import twoValuesColumn from '@/pages/serviceActivity/residential/tableComponents/twoValuesColumn.tsx'
import { TicketPriorityCell } from '@/components/table/cells/TicketPriorityCell.tsx'
import { Badge } from '@/components/core/badges/Badge.tsx'
import { ticketPriorityTableRowSort } from '@/components/table/sort/ticketPrioritySort.ts'
import { PageName, ResidentialServiceActivity } from '@/generated'
import { AssetDetailLink } from '@/pages/assetdetail/AssetDetailLink.tsx'
import { facetFilterFn } from '@/components/core/table/filters/facetFilterFn.ts'
import { serviceActivityPendingClientActionFilterDataAccessor } from '@/features/serviceactivity/serviceActivityFilters.ts'
import { dateRangeFilterFn } from '@/components/core/table/filters/dateRangeFilterFn.ts'
import { DateTimeCell } from '@/components/core/table/cells/DateTimeCell.tsx'
import { ServiceActivityStatusCell } from '@/components/table/cells/ServiceActivityStatusCell.tsx'
import numericColumn from '@/components/core/table/columns/numericColumn.ts'
import { serviceActivityStatusSort } from '@/helpers/ServiceActivityHelper.ts'
import textColumn from '@/components/core/table/columns/textColumn.tsx'
import { pendingClientActionSortFn } from '@/components/table/sort/pendingClientActionSort.ts'
import PendingClientActionCell from '@/components/table/cells/PendingClientActionCell.tsx'
import { getAssetStageLabel } from '@/helpers/asset/AssetStageHelpers.ts'
import ZendeskTicketLink from '@/features/zendesk/ZendeskTicketLink.tsx'
import { calcDaysBetween } from '@/helpers/MomentHelpers.ts'
import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import { WorkOrdersCountCell } from '@/components/table/cells/WorkOrdersCountCell.tsx'
import { booleanFilterFn } from '@/components/core/table/filters/booleanFilterFn.ts'
import { DateCell } from '@/components/core/table/cells/DateCell.tsx'
import SensitiveText from '@/privacy/SensitiveText.tsx'
import SensitiveTextCell from '@/components/table/cells/SensitiveTextCell.tsx'

export default function useResidentialServiceActivityTableColumns(
  pageName: PageName
) {
  const { serviceCloudIntegrationEnabled } = useFeatureFlags()
  return useMemo<ColumnDef<ResidentialServiceActivity>[]>(() => {
    const columns: ColumnDef<ResidentialServiceActivity>[] = []
    if (pageName === PageName.ServiceActivity) {
      columns.push(
        twoValuesColumn({
          primary: {
            accessorKey: 'customerName',
            header: 'Customer Name',
            render: ctx => (
              <AssetDetailLink assetId={ctx.row.original.assetId}>
                <SensitiveText>{ctx.row.original.customerName}</SensitiveText>
              </AssetDetailLink>
            ),
          },
          secondary: {
            accessorKey: 'assetId',
            header: 'Asset ID',
          },
        })
      )
    }

    if (serviceCloudIntegrationEnabled) {
      columns.push(
        twoValuesColumn({
          primary: {
            accessorKey: 'caseNumber',
            header: 'Case Number',
          },
          secondary: {
            accessorKey: 'type',
            header: 'Case Type',
          },
        }),
        {
          id: 'workOrdersCount',
          header: 'Work Orders',
          accessorKey: 'workOrdersCount',
          enableSorting: false,
          cell: WorkOrdersCountCell,
        }
      )
    } else {
      columns.push(
        twoValuesColumn({
          primary: {
            accessorKey: 'id',
            header: 'Ticket Id',
            render: ctx => <ZendeskTicketLink ticketId={ctx.row.original.id} />,
          },
          secondary: {
            accessorKey: 'type',
            header: 'Ticket Type',
          },
        })
      )
    }
    columns.push(
      twoValuesColumn({
        id: 'createdAt',
        primary: {
          accessorKey: 'createdAt',
          header: serviceCloudIntegrationEnabled ? 'Case Age' : 'Ticket Age',
          render: ctx => {
            const daysOpen = calcDaysBetween(ctx.row.original.createdAt)
            return `${daysOpen.toString()} day${daysOpen === 1 ? '' : 's'}`
          },
        },
        secondary: {
          accessorKey: 'createdAt',
          header: 'Created Date',
          render: DateCell,
        },
        accessorFn: originalRow => originalRow.createdAt,
        filterFn: dateRangeFilterFn<ResidentialServiceActivity>,
        enableSorting: true,
      }),
      {
        id: 'priority',
        accessorKey: 'priority',
        header: 'Priority',
        cell: TicketPriorityCell,
        sortingFn: ticketPriorityTableRowSort(row =>
          row.original.priority?.toString()
        ),
        filterFn: facetFilterFn,
      },
      textColumn({
        key: 'subject',
        header: 'Subject',
        cell: SensitiveTextCell,
      }),
      {
        id: 'creationReason',
        accessorKey: 'creationReason',
        header: 'Creation Reason',
        filterFn: facetFilterFn,
      },
      {
        id: 'status',
        accessorKey: 'status',
        header: 'Status',
        filterFn: facetFilterFn,
        cell: ServiceActivityStatusCell,
        sortingFn: (
          rowA: Row<ResidentialServiceActivity>,
          rowB: Row<ResidentialServiceActivity>
        ) =>
          serviceActivityStatusSort(rowA.original.status, rowB.original.status),
      },
      {
        id: 'updatedAt',
        accessorKey: 'updatedAt',
        enableSorting: true,
        header: 'Last Updated',
        filterFn: dateRangeFilterFn,
        cell: DateTimeCell,
      },
      {
        id: 'pendingClientAction',
        accessorKey: 'pendingClientAction',
        enableSorting: true,
        sortingFn: pendingClientActionSortFn,
        header: 'Pending Client Action',
        filterFn: serviceCloudIntegrationEnabled
          ? booleanFilterFn
          : facetFilterFn,
        accessorFn: serviceCloudIntegrationEnabled
          ? undefined
          : serviceActivityPendingClientActionFilterDataAccessor,
        cell: PendingClientActionCell,
      }
    )
    if (
      pageName === PageName.ServiceActivity &&
      !serviceCloudIntegrationEnabled
    ) {
      columns.push(
        numericColumn({
          key: 'otherOpenServiceActivitiesCount',
          header: 'Other Open Tickets',
          cell: ctx => (
            <div className="tw-flex tw-flex-row-reverse">
              <Badge color="gray" className="tw-w-2">
                {ctx.row.original.otherOpenServiceActivitiesCount}
              </Badge>
            </div>
          ),
          format: 'integer',
          mode: 'sortable',
        })
      )
    }
    // Additional (non-visible) columns needed to support filtering/searching
    columns.push(
      {
        id: 'type',
        accessorKey: 'type',
        filterFn: facetFilterFn,
      },
      {
        id: 'assetStage',
        accessorFn: row => getAssetStageLabel(row.assetStage),
        filterFn: facetFilterFn,
      },
      {
        id: 'isEscalated',
        accessorKey: 'isEscalated',
        filterFn: booleanFilterFn,
      },
      {
        id: 'isNonCoveredRequest',
        accessorKey: 'isNonCoveredRequest',
        filterFn: booleanFilterFn,
      },
      {
        id: 'serviceType',
        accessorKey: 'serviceType',
        filterFn: facetFilterFn,
      },
      // Supported for global search
      {
        id: 'clientAssetId',
        accessorKey: 'clientAssetId',
      }
    )
    return columns
  }, [pageName, serviceCloudIntegrationEnabled])
}

export const ResidentialServiceActivityTableColumnVisibility: VisibilityState =
  {
    type: false,
    assetStage: false,
    isEscalated: false,
    isNonCoveredRequest: false,
    serviceType: false,
    clientAssetId: false,
  }
