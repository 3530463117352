import React, { PropsWithChildren, useMemo } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { AuthContext, AuthContextValue } from '@/contexts/auth/AuthContext.ts'

export function AuthProvider(
  props: Readonly<PropsWithChildren>
): React.JSX.Element {
  const { oktaAuth, authState } = useOktaAuth()
  const contextValue: AuthContextValue = useMemo(
    () => ({
      isAuthenticated: authState?.isAuthenticated,
      authError: authState?.error,
      accessToken: authState?.accessToken?.accessToken,
      userName: authState?.idToken?.claims.name,
      userEmail: authState?.idToken?.claims.email,
      signInWithRedirect: (originalUri?: string) =>
        oktaAuth.signInWithRedirect({
          originalUri,
        }),
      handleLoginRedirect: () => oktaAuth.handleLoginRedirect(),
      signOut: () => oktaAuth.signOut(),
    }),
    [oktaAuth, authState]
  )
  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  )
}
