import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react'
import { CaseCommunication } from '@/generated'
import Header from '@/features/serviceactivity/detailsDrawer/communications/components/item/header/Header.tsx'
import CaseEmailContent from '@/features/serviceactivity/detailsDrawer/communications/components/item/body/CaseEmailContent.tsx'
import CaseVoiceCallContent from '@/features/serviceactivity/detailsDrawer/communications/components/item/body/CaseVoiceCallContent.tsx'
import { useCaseCommunicationsContext } from '@/features/serviceactivity/detailsDrawer/communications/components/list/context.ts'
import CaseSMSContent from '@/features/serviceactivity/detailsDrawer/communications/components/item/body/CaseSMSContent.tsx'

export type Props = Readonly<{
  item: CaseCommunication
  index: number
}>

export default function CaseCommunicationDisclosure({ item, index }: Props) {
  const {
    timestamp,
    type,
    emailMetadata,
    isInitiatedByOmnidian,
    voiceCallMetadata,
    smsMetadata,
    fromName,
    toName,
  } = item
  const { isAllExpanded } = useCaseCommunicationsContext()
  const borderClass = index === 0 ? 'tw-border-y' : 'tw-border-b'
  const defaultOpen = isAllExpanded ?? index <= 2
  return (
    <Disclosure
      key={`${timestamp}-${isAllExpanded}`}
      defaultOpen={defaultOpen}
      as="div"
      className={`tw-flex tw-flex-col tw-gap-6 tw-border-gray-200 tw-p-6 ${borderClass}`}
    >
      <DisclosureButton className="tw-group tw-w-full tw-items-center">
        <Header
          type={type}
          timestamp={timestamp}
          isFromOmnidianEmployee={isInitiatedByOmnidian}
          fromName={fromName}
          toName={toName}
        />
      </DisclosureButton>
      <DisclosurePanel className="tw-flex tw-flex-col tw-gap-6">
        {emailMetadata?.content && (
          <CaseEmailContent html={emailMetadata.content} />
        )}
        {voiceCallMetadata && <CaseVoiceCallContent communication={item} />}
        {smsMetadata && <CaseSMSContent communication={item} />}
      </DisclosurePanel>
    </Disclosure>
  )
}
