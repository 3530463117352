import { Avatar } from '@/components/core/avatar/Avatar.tsx'
import { Icon } from '@iconify/react'
import phoneIcon from '@iconify/icons-material-symbols/call-outline'
import emailIcon from '@iconify/icons-material-symbols/mail-outline'
import smsIcon from '@iconify/icons-material-symbols/sms-outline'
import chevronDown from '@iconify/icons-heroicons/chevron-down'
import { CaseCommunicationTypeEnum } from '@/generated'
import omnidianMark from '@/assets/icons/omnidian-mark.png'
import { DateTimeFormat } from '@/helpers/NativeDateTimeHelpers.ts'

export type Props = Readonly<{
  type: CaseCommunicationTypeEnum
  fromName: string | null
  toName: string | null
  isFromOmnidianEmployee: boolean
  timestamp: string | null
}>

export default function Header({
  type,
  fromName,
  toName,
  isFromOmnidianEmployee,
  timestamp,
}: Props) {
  return (
    <div
      className="tw-flex tw-justify-between"
      data-testid="case-communication-header"
    >
      <div className="tw-flex tw-gap-3">
        <Icon
          data-testid="chevron-icon"
          icon={chevronDown}
          className="tw-size-5 tw-fill-white/60 group-data-[open]:tw-rotate-180 group-data-[hover]:tw-fill-white/50"
        />
        <Avatar
          fullName={fromName ?? ''}
          profileImageUrl={isFromOmnidianEmployee ? omnidianMark : undefined}
        />
        <div className="tw-text-left">
          <div className="tw-flex tw-items-center tw-gap-2 tw-text-gray-900">
            {type === CaseCommunicationTypeEnum.VoiceCall && (
              <Icon data-testid="phone-icon" icon={phoneIcon} />
            )}
            {type === CaseCommunicationTypeEnum.Sms && (
              <Icon data-testid="email-icon" icon={smsIcon} />
            )}
            {type === CaseCommunicationTypeEnum.EmailMessage && (
              <Icon data-testid="email-icon" icon={emailIcon} />
            )}
            <span className="tw-font-medium">{fromName}</span>
            {isFromOmnidianEmployee ? ' (Omnidian Technical Support)' : ''}
          </div>
          <div className="">{toName}</div>
        </div>
      </div>
      {timestamp && (
        <div className="tw-text-sm tw-font-normal tw-text-gray-700">
          {DateTimeFormat.format(new Date(timestamp))}
        </div>
      )}
    </div>
  )
}
