import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useAuthContext } from '@/contexts/auth/AuthContext.ts'
import * as Sentry from '@sentry/react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { ConsentContext, ConsentTypes } from '@/consent/ConsentContext.ts'
import { useActuatorInfoQuery } from '@/o11y/ActuatorQueries.ts'
import {
  SentryContext,
  SentryContextValue,
  SentryInitializationState,
} from '@/o11y/SentryContext.ts'

export function SentryContextProvider({
  children,
}: Readonly<PropsWithChildren>): React.ReactNode {
  const authContext = useAuthContext()
  const consentContext = useContext(ConsentContext)
  const [contextValue, setContextValue] = useState<SentryContextValue>({
    initializationState: SentryInitializationState.PreInitialization,
  })
  const {
    data: actuatorInfo,
    isLoading: actuatorInfoIsLoading,
    isError: actuatorInfoIsError,
  } = useActuatorInfoQuery()

  useEffect(() => {
    const accessToken = authContext.accessToken
    if (
      authContext.isAuthenticated &&
      accessToken !== undefined &&
      consentContext !== null &&
      !actuatorInfoIsLoading
    ) {
      const headerAuthorizationToken = 'Bearer ' + accessToken
      const analyticsConsent =
        consentContext.consents.get(ConsentTypes.Analytics) ?? false

      // only enable Sentry in the production environment if the user has opted into analytics
      if (
        analyticsConsent &&
        !actuatorInfoIsError &&
        actuatorInfo !== undefined &&
        actuatorInfo.sentry.environment === 'production' &&
        actuatorInfo.sentry.dsn !== ''
      ) {
        Sentry.init({
          dsn: actuatorInfo.sentry.dsn,
          release: actuatorInfo.git.commit.id.abbrev,
          environment: actuatorInfo.sentry.environment,
          tunnel: '/api/sentry',
          transportOptions: {
            headers: {
              Authorization: headerAuthorizationToken,
            },
          },
          initialScope: {
            user: {
              id: authContext.userName,
              email: authContext.userEmail,
            },
          },
          integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
              useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes,
            }),
            Sentry.extraErrorDataIntegration(),
          ],
        })

        setContextValue({
          initializationState: SentryInitializationState.Initialized,
        })
      } else {
        setContextValue({
          initializationState: SentryInitializationState.WontInitialize,
        })
      }
    }
  }, [
    authContext,
    consentContext,
    actuatorInfo,
    actuatorInfoIsLoading,
    actuatorInfoIsError,
  ])

  return (
    <SentryContext.Provider value={contextValue}>
      {children}
    </SentryContext.Provider>
  )
}
