export type Props = Readonly<{
  quotesLength: number
}>

export default function QuoteTitle({ quotesLength }: Props) {
  const endString = quotesLength > 1 ? `s (${quotesLength})` : ''
  return (
    <div className="tw-mb-4 tw-text-lg tw-font-medium">
      Service Quote{endString}
    </div>
  )
}
