import QuoteStatus from '@/features/serviceactivity/detailsDrawer/workOrders/quotes/components/header/status/QuoteStatus.tsx'
import QuoteAction from '@/features/serviceactivity/detailsDrawer/workOrders/quotes/components/footer/QuoteAction.tsx'
import { WorkOrderQuoteStatusEnum } from '@/generated'
import SPLIT_QUOTE_MESSAGE from '@/features/serviceactivity/detailsDrawer/workOrders/quotes/components/body/split/SPLIT_QUOTE_MESSAGE.ts'

export type Props = Readonly<{
  aggregateQuoteStatus?: WorkOrderQuoteStatusEnum
}>
export default function SplitQuoteAlert({ aggregateQuoteStatus }: Props) {
  return (
    <div className="tw-flex tw-w-full tw-flex-col tw-gap-6 tw-rounded-xl tw-border tw-border-gray-200 tw-p-6">
      <div className="tw-flex tw-justify-between">
        <h3 className="tw-text-lg tw-font-medium tw-text-gray-900">
          Quote Details
        </h3>
        <QuoteStatus status={aggregateQuoteStatus} />
      </div>
      <div className="tw-rounded tw-bg-blue-50 tw-p-4">
        <div className="tw-mb-2 tw-text-base tw-font-semibold tw-text-blue-700">
          {SPLIT_QUOTE_MESSAGE.title}
        </div>
        <div className="tw-text-sm tw-text-blue-700">
          {SPLIT_QUOTE_MESSAGE.description}
        </div>
      </div>
      <QuoteAction />
    </div>
  )
}
