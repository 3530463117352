import { useCaseCommunicationsContext } from '@/features/serviceactivity/detailsDrawer/communications/components/list/context.ts'
import { DateTimeFormat } from '@/helpers/NativeDateTimeHelpers.ts'
import omnidianMark from '@/assets/icons/omnidian-mark.png'
import { Avatar } from '@/components/core/avatar/Avatar.tsx'

export default function Transcript() {
  const { transcriptData } = useCaseCommunicationsContext()

  if (!transcriptData) {
    return null
  }
  return (
    <div className="tw-flex tw-h-full tw-flex-col tw-overflow-y-auto tw-rounded-lg tw-bg-gray-50 tw-p-4 tw-shadow-md">
      <div className="tw-flex-1 tw-overflow-y-auto tw-pt-2">
        {transcriptData.map(message => (
          <div
            key={message.timeStamp}
            data-testid="transcript-entry"
            className="tw-mb-4 tw-pl-1"
          >
            <div className="tw-flex tw-items-start">
              <div className="tw-mr-2 tw-shrink-0">
                {message.role === 'agent' ? (
                  <Avatar
                    fullName={'Customer'}
                    profileImageUrl={omnidianMark}
                  />
                ) : (
                  <div
                    className={`tw-flex tw-size-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-700 tw-ring-2`}
                  >
                    <span className="tw-text-sm tw-text-white">CU</span>
                  </div>
                )}
              </div>

              <div
                className={`tw-max-w-[80%] tw-rounded-r-2xl tw-rounded-bl-2xl tw-px-4 tw-py-3 ${
                  message.role === 'agent'
                    ? 'tw-border tw-border-gray-200 tw-bg-white'
                    : 'tw-border tw-border-gray-200 tw-bg-gray-100'
                }`}
              >
                {message.role === 'agent' && (
                  <div className="tw-mb-1 tw-font-medium tw-text-omnidian-orange">
                    Omnidian Support
                  </div>
                )}
                {message.role === 'customer' && (
                  <div className="tw-mb-1 tw-font-medium tw-text-gray-700">
                    Customer
                  </div>
                )}
                <div className="tw-text-sm">{message.messageText}</div>
                <div className="tw-mt-1 tw-text-xs tw-text-gray-500">
                  {message.timeStamp &&
                    DateTimeFormat.format(new Date(message.timeStamp))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
