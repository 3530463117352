import Button from '@/components/core/buttons/Button.tsx'
import { Icon } from '@iconify/react'
import barsUp from '@iconify/icons-heroicons/bars-arrow-up'
import barsDown from '@iconify/icons-heroicons/bars-arrow-down'
import upDown from '@iconify/icons-heroicons/chevron-up-down'
import { useCaseCommunicationsContext } from '@/features/serviceactivity/detailsDrawer/communications/components/list/context.ts'

export default function Header() {
  const { toggleIsAllExpanded, toggleSortType, sortType } =
    useCaseCommunicationsContext()
  return (
    <div className="tw-flex tw-justify-between tw-p-2">
      <div className="tw-text-lg tw-font-medium tw-text-gray-900">
        Customer Communication
      </div>
      <div className="tw-flex tw-gap-2">
        <Button
          color="light"
          onClick={() => {
            toggleSortType()
          }}
        >
          <div className="tw-flex tw-justify-normal tw-gap-2">
            {sortType === 'LATEST_FIRST' ? 'Newest first' : 'Oldest First'}
            <Icon
              icon={sortType === 'LATEST_FIRST' ? barsUp : barsDown}
              className="tw-size-5 tw-fill-white/60 group-data-[open]:tw-rotate-180 group-data-[hover]:tw-fill-white/50"
            />
          </div>
        </Button>
        <Button
          color="light"
          onClick={() => {
            toggleIsAllExpanded()
          }}
        >
          <div className="tw-flex tw-justify-normal tw-gap-2">
            <Icon
              icon={upDown}
              className="tw-size-5 tw-fill-white/60 group-data-[open]:tw-rotate-180 group-data-[hover]:tw-fill-white/50"
            />
          </div>
        </Button>
      </div>
    </div>
  )
}
